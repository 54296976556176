import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getEntityList } from "../../../actions/EntityAction";
import {
  AddAccount,
  deleteAccount,
  editAccount,
  getAccountList,
} from "../../../actions/AccountAction";
import { getAccountGroupList } from "../../../actions/AccountGroupAction";
import { checkNumberWithCommas, numberWithCommas } from "../../../utils";

class AccountList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idEntity: "",

      accounts: false,
      accountStartBalanceList: false,
      debetBalance: 0,
      creditBalance: 0,

      isEditing: false,
      searchText: "Cari",
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { idEntity } = this.state;
    if (idEntity) {
      this.setState({ accounts: false });
      this.props.dispatch(getAccountList(idEntity));
    } else if (!idEntity) {
      this.props.dispatch(getEntityList());
      this.props.dispatch(getAccountGroupList());
    }
  };

  calculateData = (data) => {
    let debetBalanceTemp = 0;
    let creditbalanceTemp = 0;
    data.forEach((account) => {
      if (account.normal_balance === "D") {
        debetBalanceTemp =
          debetBalanceTemp +
          parseInt(account.start_balance.replace(/\./g, "")) +
          parseInt(account.start_balance_total);
      }
      if (account.normal_balance === "K") {
        creditbalanceTemp =
          creditbalanceTemp +
          parseInt(account.start_balance.replace(/\./g, "")) +
          parseInt(account.start_balance_total);
      }
    });

    this.setState({
      debetBalance: debetBalanceTemp,
      creditBalance: creditbalanceTemp,
      accounts: data,
      searchText: "Refresh",
      isEditing: false,
    });
  };

  componentDidUpdate(prevProps) {
    const {
      editAccountResult,
      editAccountError,
      getAccountError,
      getAccountResult,
      getEntityError,
      addAccountResult,
      addAccountError,
      getAccountGroupError,
      deleteAccountResult,
      deleteAccountError,
      getCashAccountBalanceTotalError,
      getCashAccountBalanceTotalResult,
    } = this.props;
    if (
      editAccountResult &&
      prevProps.editAccountResult !== editAccountResult
    ) {
      swal("Sukses", editAccountResult.messages, "success");
      this.getData();
    }

    if (
      deleteAccountError &&
      prevProps.deleteAccountError !== deleteAccountError
    ) {
      swal("Error", deleteAccountError.messages.error, "error");
    }

    if (
      deleteAccountResult &&
      prevProps.deleteAccountResult !== deleteAccountResult
    ) {
      swal("Sukses", deleteAccountResult.messages, "success");
      this.getData();
    }

    if (addAccountResult && prevProps.addAccountResult !== addAccountResult) {
      // swal("Sukses", addAccountResult.messages, "success");
      this.getData();
    }

    if (addAccountError && prevProps.addAccountError !== addAccountError) {
      swal("Error", addAccountError.messages.error, "error");
    }

    if (getAccountResult && prevProps.getAccountResult !== getAccountResult) {
      this.calculateData(getAccountResult.data);
    }

    if (
      getCashAccountBalanceTotalResult &&
      prevProps.getCashAccountBalanceTotalResult !==
        getCashAccountBalanceTotalResult
    ) {
      this.setState({
        accountStartBalanceList: getCashAccountBalanceTotalResult.data,
      });
    }

    if (
      getAccountGroupError &&
      prevProps.getAccountGroupError !== getAccountGroupError
    ) {
      swal("Error!", getAccountGroupError.messages.error, "error");
    }

    if (getEntityError && prevProps.getEntityError !== getEntityError) {
      swal("Error!", getEntityError.messages.error, "error");
      this.props.navigate("/");
    }
    if (getAccountError && prevProps.getAccountError !== getAccountError) {
      swal("Error!", getAccountError.messages.error, "error");
    }

    if (
      getCashAccountBalanceTotalError &&
      prevProps.getCashAccountBalanceTotalError !==
        getCashAccountBalanceTotalError
    ) {
      swal("Error!", getCashAccountBalanceTotalError.messages.error, "error");
    }

    if (editAccountError && prevProps.editAccountError !== editAccountError) {
      swal("Error!", editAccountError.messages.error, "error");
    }
  }

  handleChange = (event, index) => {
    const { accounts } = this.state;
    const { getAccountGroupResult } = this.props;

    const updatedAccounts = [...accounts];
    updatedAccounts[index][event.target.name] = event.target.value;

    if (event.target.name === "id_account_group") {
      updatedAccounts[index]["can_edit_start_balance"] =
        getAccountGroupResult.data[event.target.selectedIndex][
          "can_edit_start_balance"
        ];
      updatedAccounts[index]["start_balance"] = "0";
    }

    this.calculateData(updatedAccounts);
    this.setState({
      isEditing: true,
    });
  };

  handleSelectChange = (event) => {
    const value = event.target.value;
    this.setState({
      [event.target.name]: value,
      searchText: "Cari",
      accounts: false,
      debetBalance: 0,
      creditBalance: 0,
    });
  };

  handleCheckboxChange = (event, index) => {
    const { accounts } = this.state;

    const updatedAccounts = [...accounts];
    const checked = event.target.checked ? 1 : 0;
    updatedAccounts[index][event.target.name] = checked;

    this.setState({
      accounts: updatedAccounts,
      isEditing: true,
    });
  };

  saveChanging = () => {
    const { accounts, debetBalance, creditBalance } = this.state;

    if (debetBalance === creditBalance)
      this.props.dispatch(editAccount(accounts));
    else swal("Error", "Debet dan Kredit tidak sama!!", "error");
  };

  addData = () => {
    const { idEntity, isEditing } = this.state;
    if (isEditing)
      swal(
        "Error",
        "Data sedang diubah, simpan data terlebih dahulu!",
        "error"
      );
    else this.props.dispatch(AddAccount({ idEntity }));
  };

  deleteData = (id) => {
    const { isEditing, debetBalance, creditBalance, accounts } = this.state;
    const deletedData = accounts.filter((account) => account.id === id);

    if (isEditing)
      swal(
        "Error",
        "Data sedang diubah, simpan data terlebih dahulu!",
        "error"
      );
    else if (debetBalance === creditBalance && deletedData[0].start_balance > 0)
      swal(
        "Error",
        "Setelah dihapus Debet dan Kredit tidak sama!! Perbaiki Saldo Awal Dahulu",
        "error"
      );
    else this.props.dispatch(deleteAccount(id));
  };

  checkDisableEdit = (id_default_chart_of_account) => {
    return !id_default_chart_of_account ? false : true;
  };

  checkNumberAccountNumber = (x) => {
    return !isNaN(+x) ? x.toString().replace(".", "") : "";
  };

  render() {
    const { idEntity, accounts, debetBalance, creditBalance, searchText } =
      this.state;
    const {
      getAccountLoading,
      editAccountLoading,
      getEntityLoading,
      getEntityResult,
      getAccountGroupResult,
      deleteAccountLoading,
      addAccountLoading,
    } = this.props;
    const mystyle = {
      width: "25px",
      height: "25px",
      position: "relative",
    };

    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Master Account</CardTitle>
              </CardHeader>
              <CardBody>
                <Table borderless>
                  <tbody>
                    {getEntityLoading ? (
                      <tr>
                        <td className="text-center">
                          <Spinner color="primary" />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td width="20%">
                          <Input
                            type="select"
                            name="idEntity"
                            onChange={(event) => this.handleSelectChange(event)}
                            value={idEntity}
                            options={getEntityResult.data}
                            style={{ display: "block", margin: "auto" }}
                          >
                            <option value="">--Pilih Entitas--</option>
                            {getEntityResult &&
                              getEntityResult.data.map((entity, index) => (
                                <option key={index} value={entity.id}>
                                  {entity.entity_name}
                                </option>
                              ))}
                          </Input>
                        </td>
                        <td>
                          <Button
                            color="success me-2"
                            disabled={getEntityLoading}
                            onClick={this.getData}
                          >
                            {searchText}
                          </Button>
                        </td>
                        <td className="align-middle">
                          Debit : {numberWithCommas(debetBalance)}
                        </td>
                        <td className="align-middle">
                          Kredit : {numberWithCommas(creditBalance)}
                        </td>
                        <td className="float-end">
                          <Button
                            color="primary me-2"
                            disabled={!accounts}
                            onClick={() => this.addData()}
                          >
                            Tambah
                          </Button>
                          <Button
                            color="warning me-2"
                            disabled={!accounts}
                            onClick={this.saveChanging}
                          >
                            Simpan
                          </Button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>No</th>
                      <th width="10%">Nomor</th>
                      <th width="20%">Kelompok</th>
                      <th>Nama</th>
                      <th width="8%">Saldo Normal</th>
                      <th width="15%">Saldo Awal</th>
                      <th width="5%">Kas Keluar</th>
                      <th width="5%">Kas Masuk</th>
                      <th width="5%" className="text-center">
                        *
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!addAccountLoading &&
                    !deleteAccountLoading &&
                    !editAccountLoading &&
                    accounts ? (
                      accounts.map((account, index) => (
                        <tr key={index}>
                          <td className="align-middle">{index + 1}</td>
                          <td>
                            <Input
                              type="text"
                              value={this.checkNumberAccountNumber(
                                account.number
                              )}
                              name="number"
                              autoComplete="off"
                              onChange={(event) =>
                                this.handleChange(event, index)
                              }
                            />
                          </td>
                          <td>
                            <Input
                              type="select"
                              name="id_account_group"
                              onChange={(event) =>
                                this.handleChange(event, index)
                              }
                              disabled={this.checkDisableEdit(
                                account.id_default_chart_of_account
                              )}
                              value={account.id_account_group}
                              options={getAccountGroupResult.data}
                              style={{ display: "block", margin: "auto" }}
                            >
                              {getAccountGroupResult &&
                                getAccountGroupResult.data.map(
                                  (account, index) => (
                                    <option key={index} value={account.id}>
                                      {account.name}
                                    </option>
                                  )
                                )}
                            </Input>
                          </td>
                          <td>
                            <Input
                              type="text"
                              value={account.name}
                              name="name"
                              autoComplete="off"
                              onChange={(event) =>
                                this.handleChange(event, index)
                              }
                            />
                          </td>
                          <td>
                            <Input
                              type="select"
                              name="normal_balance"
                              disabled={this.checkDisableEdit(
                                account.id_default_chart_of_account
                              )}
                              onChange={(event) =>
                                this.handleChange(event, index)
                              }
                              value={account.normal_balance}
                              options={getAccountGroupResult.data}
                              style={{ display: "block", margin: "auto" }}
                            >
                              <option value="D">D</option>
                              <option value="K">K</option>
                            </Input>
                          </td>
                          <td>
                            {account.can_edit_start_balance === "1" ? (
                              <Input
                                type="text"
                                value={checkNumberWithCommas(
                                  account.start_balance
                                )}
                                name="start_balance"
                                autoComplete="off"
                                onChange={(event) =>
                                  this.handleChange(event, index)
                                }
                              />
                            ) : (
                              <Input
                                type="text"
                                value={numberWithCommas(
                                  account.start_balance_total
                                )}
                                name="start_balance_total"
                                autoComplete="off"
                                disabled={true}
                              />
                            )}
                          </td>
                          <td className="text-center">
                            <Input
                              type="checkbox"
                              name="cash_out"
                              disabled={this.checkDisableEdit(
                                account.id_default_chart_of_account
                              )}
                              style={mystyle}
                              checked={parseInt(account.cash_out)}
                              onChange={(event) =>
                                this.handleCheckboxChange(event, index)
                              }
                            />
                          </td>
                          <td className="text-center">
                            <Input
                              type="checkbox"
                              name="cash_in"
                              disabled={this.checkDisableEdit(
                                account.id_default_chart_of_account
                              )}
                              style={mystyle}
                              checked={parseInt(account.cash_in)}
                              onChange={(event) =>
                                this.handleCheckboxChange(event, index)
                              }
                            />
                          </td>
                          <td className="text-center">
                            {!this.checkDisableEdit(
                              account.id_default_chart_of_account
                            ) && (
                              <Button
                                color="danger"
                                onClick={() => this.deleteData(account.id)}
                              >
                                X
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : addAccountLoading ||
                      getAccountLoading ||
                      editAccountLoading ||
                      deleteAccountLoading ? (
                      <tr>
                        <td colSpan={9} align="center">
                          <Spinner color="primary" />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={9} align="center">
                          Data Kosong
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  getEntityLoading: state.EntityReducer.getEntityLoading,
  getEntityResult: state.EntityReducer.getEntityResult,
  getEntityError: state.EntityReducer.getEntityError,

  getAccountLoading: state.AccountReducer.getAccountLoading,
  getAccountResult: state.AccountReducer.getAccountResult,
  getAccountError: state.AccountReducer.getAccountError,

  getAccountGroupLoading: state.AccountGroupReducer.getAccountGroupLoading,
  getAccountGroupResult: state.AccountGroupReducer.getAccountGroupResult,
  getAccountGroupError: state.AccountGroupReducer.getAccountGroupError,

  getCashAccountBalanceTotalLoading:
    state.CashAccountReducer.getCashAccountBalanceTotalLoading,
  getCashAccountBalanceTotalResult:
    state.CashAccountReducer.getCashAccountBalanceTotalResult,
  getCashAccountBalanceTotalError:
    state.CashAccountReducer.getCashAccountBalanceTotalError,

  editAccountLoading: state.AccountReducer.editAccountLoading,
  editAccountResult: state.AccountReducer.editAccountResult,
  editAccountError: state.AccountReducer.editAccountError,

  addAccountLoading: state.AccountReducer.addAccountLoading,
  addAccountResult: state.AccountReducer.addAccountResult,
  addAccountError: state.AccountReducer.addAccountError,

  deleteAccountLoading: state.AccountReducer.deleteAccountLoading,
  deleteAccountResult: state.AccountReducer.deleteAccountResult,
  deleteAccountError: state.AccountReducer.deleteAccountError,
});

export default connect(mapStateToProps, null)(AccountList);
