import {
  ADD_TRANSACTION,
  DELETE_TRANSACTION,
  EDIT_TRANSACTION,
  GET_TRANSACTION_LIST,
} from "../../actions/TransactionAction";

const initialState = {
  getTransactionLoading: false,
  getTransactionResult: false,
  getTransactionError: false,

  editTransactionLoading: false,
  editTransactionResult: false,
  editTransactionError: false,

  addTransactionLoading: false,
  addTransactionResult: false,
  addTransactionError: false,

  deleteTransactionLoading: false,
  deleteTransactionResult: false,
  deleteTransactionError: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TRANSACTION_LIST:
      return {
        ...state,
        getTransactionLoading: action.payload.loading,
        getTransactionResult: action.payload.data,
        getTransactionError: action.payload.errorMessage,
      };
    case ADD_TRANSACTION:
      return {
        ...state,
        addTransactionLoading: action.payload.loading,
        addTransactionResult: action.payload.data,
        addTransactionError: action.payload.errorMessage,
      };
    case EDIT_TRANSACTION:
      return {
        ...state,
        editTransactionLoading: action.payload.loading,
        editTransactionResult: action.payload.data,
        editTransactionError: action.payload.errorMessage,
      };
    case DELETE_TRANSACTION:
      return {
        ...state,
        deleteTransactionLoading: action.payload.loading,
        deleteTransactionResult: action.payload.data,
        deleteTransactionError: action.payload.errorMessage,
      };
    default:
      return state;
  }
}
