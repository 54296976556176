import React from "react";
import ReactDOM from "react-dom/client";
import { APPWithRouter } from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import reducers from "./reducers";
import axios from "axios";
import { BrowserRouter } from "react-router-dom";
import { API_URL } from "./utils";

// axios.defaults.withCredentials = true;
axios.defaults.baseURL = API_URL;

const store = createStore(reducers, compose(applyMiddleware(thunk)));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <APPWithRouter />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
