import {
  dispatchError,
  dispatchLoading,
  dispatchSuccess,
  getLocalStorage,
} from "../utils";
import axios from "axios";

export const GET_ENTITY_LIST = "GET_ENTITY_LIST";
export const GET_DETAIL_ENTITY = "GET_DETAIL_ENTITY";
export const ADD_ENTITY = "ADD_ENTITY";
export const EDIT_ENTITY = "EDIT_ENTITY";
export const EDIT_ENTITY_START_DATE = "EDIT_ENTITY_START_DATE";
export const DELETE_ENTITY = "DELETE_ENTITY";

export const getEntityList = (searchText = "") => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_ENTITY_LIST);
    axios({
      method: "GET",
      url: "entity?searchText=" + searchText,
      timeout: 120000,
      headers: {
        "Content-Type": "application/json",
        Authorization: getLocalStorage("user").token,
      },
    })
      .then((response) => {
        dispatchSuccess(dispatch, GET_ENTITY_LIST, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, GET_ENTITY_LIST, error.response.data);
      });
  };
};

export const getDetailEntity = (id) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_DETAIL_ENTITY);
    axios({
      method: "GET",
      url: "entity?id=" + id,
      timeout: 120000,
      headers: {
        "Content-Type": "application/json",
        Authorization: getLocalStorage("user").token,
      },
    })
      .then((response) => {
        dispatchSuccess(dispatch, GET_DETAIL_ENTITY, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, GET_DETAIL_ENTITY, error.response.data);
      });
  };
};

export const addEntity = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, ADD_ENTITY);
    axios({
      method: "POST",
      url: "add-entity",
      data,
      timeout: 120000,
      headers: {
        "Content-Type": "application/json",
        Authorization: getLocalStorage("user").token,
      },
    })
      .then((response) => {
        dispatchSuccess(dispatch, ADD_ENTITY, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, ADD_ENTITY, error.response.data);
      });
  };
};

export const editEntity = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, EDIT_ENTITY);
    axios({
      method: "PUT",
      url: "update-entity",
      data,
      timeout: 120000,
      headers: {
        "Content-Type": "application/json",
        Authorization: getLocalStorage("user").token,
      },
    })
      .then((response) => {
        dispatchSuccess(dispatch, EDIT_ENTITY, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, EDIT_ENTITY, error.response.data);
      });
  };
};

export const deleteEntity = (id) => {
  return (dispatch) => {
    dispatchLoading(dispatch, DELETE_ENTITY);
    axios({
      method: "DELETE",
      url: "entity/" + id,
      timeout: 120000,
      headers: {
        "Content-Type": "application/json",
        Authorization: getLocalStorage("user").token,
      },
    })
      .then((response) => {
        dispatchSuccess(dispatch, DELETE_ENTITY, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, DELETE_ENTITY, error.response.data);
      });
  };
};

export const addEntityStartDate = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, ADD_ENTITY);
    axios({
      method: "POST",
      url: "add-entity-start-date",
      data,
      timeout: 120000,
      headers: {
        "Content-Type": "application/json",
        Authorization: getLocalStorage("user").token,
      },
    })
      .then((response) => {
        dispatchSuccess(dispatch, ADD_ENTITY, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, ADD_ENTITY, error.response.data);
      });
  };
};

export const editEntityStartDate = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, EDIT_ENTITY_START_DATE);

    axios({
      method: "PUT",
      url: "update-entity-start-date",
      data,
      timeout: 120000,
      headers: {
        "Content-Type": "application/json",
        Authorization: getLocalStorage("user").token,
      },
    })
      .then((response) => {
        dispatchSuccess(dispatch, EDIT_ENTITY_START_DATE, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, EDIT_ENTITY_START_DATE, error.response.data);
      });
  };
};

export const deleteEntityStartDate = (id) => {
  return (dispatch) => {
    dispatchLoading(dispatch, DELETE_ENTITY);
    axios({
      method: "DELETE",
      url: "entity-start-date/" + id,
      timeout: 120000,
      headers: {
        "Content-Type": "application/json",
        Authorization: getLocalStorage("user").token,
      },
    })
      .then((response) => {
        dispatchSuccess(dispatch, DELETE_ENTITY, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, DELETE_ENTITY, error.response.data);
      });
  };
};
