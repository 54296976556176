import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faArrowLeft,
  faUserCog,
  faMoneyBill,
  faMoneyCheckDollar,
} from "@fortawesome/free-solid-svg-icons";
import { NavItem, NavLink, Nav, Label } from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";

import SubMenu from "./SubMenu";

const styleNavLink = { color: "#E4E3E3", fontSize: 17 };

const SideBar = ({ isOpen, toggle }) => (
  <div className={classNames("sidebar", { "is-open": isOpen })}>
    <div className="side-menu">
      <Nav vertical className="list-unstyled pb-3">
        <Label tag="h3" style={{ color: "#E4E3E3" }} className="ms-3 mt-5 mb-3">
          <span color="info" onClick={toggle}>
            <FontAwesomeIcon icon={faArrowLeft} className="me-2" /> App Bumdes
          </span>
        </Label>

        <NavItem className="mb-2">
          <NavLink tag={Link} to={"/"} style={styleNavLink}>
            <FontAwesomeIcon icon={faHome} className="me-2  ms-3" />
            Home
          </NavLink>
        </NavItem>
        <SubMenu
          title="Modul Kas"
          styleNavLink={styleNavLink}
          icon={faMoneyCheckDollar}
          items={submenus[2]}
        />
        <SubMenu
          title="Laporan Keuangan"
          styleNavLink={styleNavLink}
          icon={faMoneyBill}
          items={submenus[1]}
        />
        <SubMenu
          title="Modul Admin"
          styleNavLink={styleNavLink}
          icon={faUserCog}
          items={submenus[0]}
        />
      </Nav>
    </div>
  </div>
);

const submenus = [
  [
    {
      title: "Entitas",
      target: "entity",
    },
    {
      title: "User",
      target: "user",
    },
    {
      title: "Rule",
      target: "rule",
    },
  ],
  [
    {
      title: "Akun",
      target: "account",
    },
    {
      title: "Buku Besar",
      target: "ledger",
    },
    {
      title: "Laporan Laba Rugi",
      target: "income-statement",
    },
  ],
  [
    {
      title: "Setting Rekening Kas",
      target: "cash-account",
    },
    {
      title: "Buku Kas",
      target: "cash-book",
    },
  ],
];

export default SideBar;
