import { EDIT_RULE, GET_RULE_LIST } from "../../actions/RuleAction";

const initialState = {
  getRuleLoading: false,
  getRuleResult: false,
  getRuleError: false,

  editRuleLoading: false,
  editRuleResult: false,
  editRuleError: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_RULE_LIST:
      return {
        ...state,
        getRuleLoading: action.payload.loading,
        getRuleResult: action.payload.data,
        getRuleError: action.payload.errorMessage,
      };
    case EDIT_RULE:
      return {
        ...state,
        editRuleLoading: action.payload.loading,
        editRuleResult: action.payload.data,
        editRuleError: action.payload.errorMessage,
      };
    default:
      return state;
  }
}
