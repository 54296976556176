import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getEntityList } from "../../../actions/EntityAction";
import { getAccountList } from "../../../actions/AccountAction";
import moment from "moment/moment";
import { numberWithCommas } from "../../../utils";

class IncomeStatementList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idEntity: "",
      entity: "",
      startDate: "",
      startDateShow: "",
      endDateShow: moment().format("YYYY-MM-DD"),

      incomeStatements: false,
      accounts: false,

      incomeNetto: false,
      costNetto: false,
      incomeBruto: false,
      costBruto: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(getEntityList());
  }

  getData = () => {
    const { startDate, startDateShow, endDateShow, accounts } = this.state;
    if (!startDateShow || !endDateShow)
      swal("Error", "Isi tanggal awal dan akhir", "error");
    else if (
      moment(startDateShow, "YYYY/MM/DD").diff(
        moment(startDate, "YYYY/MM/DD"),
        "days"
      ) < 0
    )
      swal(
        "Error",
        "Tanggal awal tidak boleh lebih kecil dari awal pencatatan",
        "error"
      );
    else {
      this.setState({
        incomeNetto: accounts.filter(
          (account) => account.type === "Income Netto"
        ),
        costNetto: accounts.filter((account) => account.type === "Cost Netto"),
        incomeBruto: accounts.filter(
          (account) => account.type === "Income Bruto"
        ),
        costBruto: accounts.filter((account) => account.type === "Cost Bruto"),
      });
    }
  };

  componentDidUpdate(prevProps) {
    const { getEntityError, getAccountResult } = this.props;

    if (getAccountResult && prevProps.getAccountResult !== getAccountResult) {
      this.setState({
        accounts: getAccountResult.data,
      });
    }

    if (getEntityError && prevProps.getEntityError !== getEntityError) {
      swal("Error!", getEntityError.messages.error, "error");
      this.props.navigate("/");
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSelectChange = (event) => {
    const value = event.target.value;
    this.setState({
      [event.target.name]: value,
    });

    if (event.target.name === "idEntity") {
      let startDateTemp = "";
      this.props.getEntityResult.data.forEach((entity) => {
        if (entity.id === event.target.value) startDateTemp = entity.start_date;
      });
      this.setState({
        accounts: false,
        startDate: moment(startDateTemp).format("YYYY-MM-DD"),
        startDateShow: moment(startDateTemp).format("YYYY-MM-DD"),
        entity: event.target[event.target.selectedIndex].text,
      });

      if (value) this.props.dispatch(getAccountList(value));
    }
  };

  render() {
    const {
      idEntity,
      accounts,
      startDateShow,
      endDateShow,
      incomeNetto,
      costNetto,
      incomeBruto,
      costBruto,
      entity,
    } = this.state;
    const { getEntityLoading, getEntityResult, getAccountLoading } = this.props;
    return (
      <>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Laporan Laba Rugi</CardTitle>
              </CardHeader>
              <CardBody>
                <Table borderless>
                  <tbody>
                    {getEntityLoading ? (
                      <tr>
                        <td className="text-center">
                          <Spinner color="primary" />
                        </td>
                      </tr>
                    ) : (
                      <>
                        <tr>
                          <td width="10%" className="align-middle">
                            Entitas
                          </td>
                          <td width="20%">
                            <Input
                              type="select"
                              name="idEntity"
                              onChange={(event) =>
                                this.handleSelectChange(event)
                              }
                              value={idEntity}
                              options={getEntityResult.data}
                              style={{ display: "block", margin: "auto" }}
                            >
                              <option value="">--Pilih Entitas--</option>
                              {getEntityResult &&
                                getEntityResult.data.map((entity, index) => (
                                  <option key={index} value={entity.id}>
                                    {entity.entity_name}
                                  </option>
                                ))}
                            </Input>
                          </td>
                          <td width="1%"></td>
                          <td width="20%"></td>
                          <td></td>
                        </tr>
                        {!accounts && getAccountLoading ? (
                          <tr>
                            <td></td>
                            <td className="text-center">
                              <Spinner color="primary" />
                            </td>
                          </tr>
                        ) : (
                          accounts && (
                            <>
                              <tr>
                                <td className="align-middle">Tanggal</td>
                                <td>
                                  <Input
                                    type="date"
                                    value={startDateShow}
                                    name="startDateShow"
                                    onChange={(event) =>
                                      this.handleChange(event)
                                    }
                                  />
                                </td>
                                <td className="text-center align-middle">-</td>
                                <td>
                                  <Input
                                    type="date"
                                    value={endDateShow}
                                    name="endDateShow"
                                    onChange={(event) =>
                                      this.handleChange(event)
                                    }
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>
                                  <Button
                                    color="success me-2"
                                    disabled={getEntityLoading}
                                    onClick={this.getData}
                                  >
                                    Lihat Laba Rugi
                                  </Button>
                                </td>
                              </tr>
                            </>
                          )
                        )}
                      </>
                    )}
                  </tbody>
                </Table>
                {incomeNetto ? (
                  <>
                    <hr />
                    <Table responsive>
                      <tbody>
                        <tr>
                          <td colSpan={4}>
                            <b>{entity}</b>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4}>
                            <b>LAPORAN LABA RUGI</b>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4}>
                            <b>
                              Dari {moment(startDateShow).format("DD/MM/YYYY")}{" "}
                              s/d {moment(endDateShow).format("DD/MM/YYYY")}
                            </b>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4}>
                            <b>{incomeBruto[0].group_name}</b>
                          </td>
                        </tr>
                        {incomeBruto.map((income, index) => (
                          <tr key={index}>
                            <td></td>
                            <td>{income.name}</td>
                            <td>{numberWithCommas(income.start_balance)}</td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={4}>
                            <b>{costBruto[0].group_name}</b>
                          </td>
                        </tr>
                        {costBruto.map((cost, index) => (
                          <tr key={index}>
                            <td></td>
                            <td>{cost.name}</td>
                            <td>{numberWithCommas(cost.start_balance)}</td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={4}>
                            <b>{incomeNetto[0].group_name}</b>
                          </td>
                        </tr>
                        {incomeNetto.map((income, index) => (
                          <tr key={index}>
                            <td></td>
                            <td>{income.name}</td>
                            <td>{numberWithCommas(income.start_balance)}</td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={4}>
                            <b>{costNetto[0].group_name}</b>
                          </td>
                        </tr>
                        {costNetto.map((cost, index) => (
                          <tr key={index}>
                            <td></td>
                            <td>{cost.name}</td>
                            <td>{numberWithCommas(cost.start_balance)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  getEntityLoading: state.EntityReducer.getEntityLoading,
  getEntityResult: state.EntityReducer.getEntityResult,
  getEntityError: state.EntityReducer.getEntityError,

  getIncomeStatementLoading:
    state.IncomeStatementReducer.getIncomeStatementLoading,
  getIncomeStatementResult:
    state.IncomeStatementReducer.getIncomeStatementResult,
  getIncomeStatementError: state.IncomeStatementReducer.getIncomeStatementError,

  getAccountLoading: state.AccountReducer.getAccountLoading,
  getAccountResult: state.AccountReducer.getAccountResult,
  getAccountError: state.AccountReducer.getAccountError,
});

export default connect(mapStateToProps, null)(IncomeStatementList);
