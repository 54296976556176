// export const BASE_URL = "http://localhost:8080/";
export const BASE_URL = "https://bumdes-api.warastra-adhiguna.com/";
export const API_URL = BASE_URL + "api/";
export const ACTIVE_USER = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : "";

// export const TOKEN = localStorage.getItem("token")
//   ? localStorage.getItem("token")
//   : "";

export const DEFAULT_TABLE_PAGE = 0;
export const DEFAULT_TABLE_ROW_PER_PAGE = 10;
