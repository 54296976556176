import { Component } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Login from "./pages/login";
import ProtectedRoute from "./routes/ProtectedRoute";
import NotFound from "./pages/notFound";
import "./App.css";
import Dashboard from "./pages/private/Dashboard";
import { UserList, AddUser, EditUser } from "./pages/private/user";
import { AddEntity, EditEntity, EntityList } from "./pages/private/entity";
import RuleList from "./pages/private/rule/RuleList";
import AccountList from "./pages/private/account/AccountList";
import LedgerList from "./pages/private/ledger/LedgerList";
import IncomeStatementList from "./pages/private/incomeStatement/IncomeStatementList";
import CashAccountList from "./pages/private/cashAccount/CashAccountList";
import CashBook from "./pages/private/cashAccount/CashBook";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Routes>
        <Route
          exact
          path="/"
          element={
            <ProtectedRoute>
              <Dashboard {...this.props} />
            </ProtectedRoute>
          }
        >
          <Route exact path="/user" element={<UserList {...this.props} />} />
          <Route exact path="/user/add" element={<AddUser {...this.props} />} />
          <Route
            exact
            path="/user/:id"
            element={<EditUser {...this.props} />}
          />
          <Route
            exact
            path="/entity"
            element={<EntityList {...this.props} />}
          />
          <Route
            exact
            path="/entity/add"
            element={<AddEntity {...this.props} />}
          />
          <Route
            exact
            path="/entity/:id"
            element={<EditEntity {...this.props} />}
          />
          <Route exact path="/rule" element={<RuleList {...this.props} />} />
          <Route
            exact
            path="/account"
            element={<AccountList {...this.props} />}
          />
          <Route
            exact
            path="/ledger"
            element={<LedgerList {...this.props} />}
          />
          <Route
            exact
            path="/income-statement"
            element={<IncomeStatementList {...this.props} />}
          />
          <Route
            exact
            path="/cash-account"
            element={<CashAccountList {...this.props} />}
          />
          <Route
            exact
            path="/cash-book"
            element={<CashBook {...this.props} />}
          />
        </Route>
        <Route path="/login" element={<Login {...this.props} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  }
}

export function APPWithRouter(props) {
  const navigate = useNavigate();
  return <App {...props} navigate={navigate}></App>;
}

export default App;
