import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import swal from "sweetalert";
import { addUser } from "../../../actions/UserAction";

class AddUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fullname: "",
      username: "",
      password: "",
      address: "",
      phone: "",
      email: "",
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    const { fullname, address, username, password } = this.state;
    event.preventDefault();
    if (fullname && address && username && password) {
      this.props.dispatch(addUser(this.state));
    } else {
      swal(
        "Failed!",
        "Nama, alamat, username dan password harus diisi",
        "error"
      );
    }
  };

  componentDidUpdate(prevProps) {
    const { addUserResult, addUserError } = this.props;
    if (addUserResult && prevProps.addUserResult !== addUserResult) {
      swal("Success!", addUserResult.messages, "success");
      this.props.navigate("/user");
    }
    if (addUserError && prevProps.addUserError !== addUserError) {
      swal("Error!", addUserError.messages.error, "error");
    }
  }

  render() {
    const { fullname, address, phone, email, username, password } = this.state;
    const { addUserLoading } = this.props;
    return (
      <>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4" className="text-center">
                  Tambah User
                </CardTitle>
              </CardHeader>
              <CardBody>
                {addUserLoading ? (
                  <div className="text-center">
                    <Spinner color="primary" />
                  </div>
                ) : (
                  <>
                    <form onSubmit={(event) => this.handleSubmit(event)}>
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <label>Nama Lengkap</label>
                            <Input
                              type="text"
                              value={fullname}
                              name="fullname"
                              autoComplete="off"
                              onChange={(event) => this.handleChange(event)}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <label>Alamat</label>
                            <Input
                              type="textarea"
                              value={address}
                              name="address"
                              autoComplete="off"
                              onChange={(event) => this.handleChange(event)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <label>Telepon</label>
                            <Input
                              type="text"
                              value={phone}
                              name="phone"
                              autoComplete="off"
                              onChange={(event) => this.handleChange(event)}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <label>Email</label>
                            <Input
                              type="email"
                              value={email}
                              name="email"
                              autoComplete="off"
                              onChange={(event) => this.handleChange(event)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <label>Username</label>
                            <Input
                              type="text"
                              value={username}
                              name="username"
                              autoComplete="off"
                              onChange={(event) => this.handleChange(event)}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <label>Password</label>
                            <Input
                              type="password"
                              value={password}
                              name="password"
                              autoComplete="off"
                              onChange={(event) => this.handleChange(event)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button color="primary">Submit</Button>
                          <Link to="/user" className="btn btn-warning">
                            Kembali
                          </Link>
                        </Col>
                      </Row>
                    </form>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  addUserLoading: state.UserReducer.addUserLoading,
  addUserResult: state.UserReducer.addUserResult,
  addUserError: state.UserReducer.addUserError,
});

export default connect(mapStateToProps, null)(AddUser);
