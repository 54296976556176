export const saveLocalStorage = (name, data) => {
  window.localStorage.setItem(name, JSON.stringify(data));
};

export const getLocalStorage = (name) => {
  const dataLocalStorage = localStorage.getItem(name);
  return dataLocalStorage ? JSON.parse(dataLocalStorage) : null;
};

export const removeLocalStorage = (name) => {
  window.localStorage.removeItem(name);
};

export const asyncLocalStorage = {
  setItem: async function (key, value) {
    await Promise.resolve();
    localStorage.setItem(key, value);
  },
  getItem: async function (key) {
    await Promise.resolve();
    return localStorage.getItem(key);
  },
  getToken: async function () {
    await Promise.resolve();
    return JSON.parse(localStorage.getItem("user")).token;
  },
  removeItem: async function (key) {
    await Promise.resolve();
    return localStorage.removeItem(key);
  },
};

// export const getToken = () =>
//   localStorage.getItem("user")
//     ? JSON.parse(localStorage.getItem("user")).token
//     : null;

// export const getAuthorizationHeader = () => getToken();

// export const axiosInstance = axios.create({
//   baseURL,
//   headers: { Authorization: getAuthorizationHeader() },
// });
