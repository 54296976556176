import React from "react";

export default function EmptyData({ colSpan }) {
  return (
    <tr>
      <td colSpan={colSpan} className="text-center">
        Data Kosong
      </td>
    </tr>
  );
}
