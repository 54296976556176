import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getEntityList } from "../../../actions/EntityAction";
import { editRule, getRuleList } from "../../../actions/RuleAction";
import { getUserList } from "../../../actions/UserAction";

class RuleList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idUser: "",
      idEntity: "",
      searchNameText: "Cari",

      rules: false,
      allPermissionCreate: 0,
      allPermissionRead: 0,
      allPermissionUpdate: 0,
      allPermissionDelete: 0,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { idUser, idEntity } = this.state;
    if (idUser && idEntity) {
      this.setState({ rules: false });
      this.props.dispatch(getRuleList({ idUser, idEntity }));
    } else if (!idUser && !idEntity) {
      this.props.dispatch(getUserList());
      this.props.dispatch(getEntityList());
    }
  };

  componentDidUpdate(prevProps) {
    const {
      editRuleResult,
      editRuleError,
      getRuleError,
      getRuleResult,
      getUserError,
      getEntityError,
    } = this.props;
    if (editRuleResult && prevProps.editRuleResult !== editRuleResult) {
      swal("Sukses", editRuleResult.messages, "success");
      this.getData();
    }

    if (getRuleResult && prevProps.getRuleResult !== getRuleResult) {
      swal("Sukses", getRuleResult.messages, "success");
      this.setState({
        rules: getRuleResult.data,
      });

      let createCount = 0;
      let readCount = 0;
      let updateCount = 0;
      let deleteCount = 0;
      getRuleResult.data.forEach((rule) => {
        if (rule["create_permission"] === "1") createCount++;
        if (rule["read_permission"] === "1") readCount++;
        if (rule["update_permission"] === "1") updateCount++;
        if (rule["delete_permission"] === "1") deleteCount++;
      });

      this.setState({
        allPermissionCreate: getRuleResult.data.length === createCount ? 1 : 0,
        allPermissionRead: getRuleResult.data.length === readCount ? 1 : 0,
        allPermissionUpdate: getRuleResult.data.length === updateCount ? 1 : 0,
        allPermissionDelete: getRuleResult.data.length === deleteCount ? 1 : 0,
        searchNameText: "Refresh",
      });
    }

    if (getUserError && prevProps.getUserError !== getUserError) {
      swal("Error!", getUserError.messages.error, "error");
      this.props.navigate("/");
    }
    if (getEntityError && prevProps.getEntityError !== getEntityError) {
      swal("Error!", getEntityError.messages.error, "error");
      this.props.navigate("/");
    }
    if (getRuleError && prevProps.getRuleError !== getRuleError) {
      swal("Error!", getRuleError.messages.error, "error");
      this.props.navigate("/");
    }

    if (editRuleError && prevProps.editRuleError !== editRuleError) {
      swal("Error!", editRuleError.messages.error, "error");
      //this.getData();
    }
  }

  handleSelectChange = (event) => {
    const value = event.target.value;
    this.setState({
      [event.target.name]: value,
    });

    this.setState({ rules: false, searchNameText: "Cari" });
  };

  handleCheckboxChange = (event, index) => {
    const { rules } = this.state;

    const updatedRules = [...rules];
    const checked = event.target.checked ? 1 : 0;
    updatedRules[index][event.target.name] = checked;

    if (event.target.name === "all_permission") {
      updatedRules[index]["create_permission"] = checked;
      updatedRules[index]["read_permission"] = checked;
      updatedRules[index]["update_permission"] = checked;
      updatedRules[index]["delete_permission"] = checked;
    }
    this.setState({
      rules: updatedRules,
    });
  };

  saveChanging = () => {
    const { rules, idUser, idEntity } = this.state;
    if (idUser && idEntity) this.props.dispatch(editRule(rules));
  };

  handleCheckboxAllChange = (event, controlledField) => {
    const checked = event.target.checked ? 1 : 0;
    this.setState({
      [event.target.name]: checked,
    });

    this.state.rules.forEach((rule) => {
      rule[controlledField] = checked;
    });
  };

  render() {
    const {
      idUser,
      idEntity,
      rules,
      searchNameText,
      allPermissionCreate,
      allPermissionRead,
      allPermissionUpdate,
      allPermissionDelete,
    } = this.state;
    const {
      getRuleLoading,
      editRuleLoading,
      getUserLoading,
      getUserResult,
      getEntityLoading,
      getEntityResult,
    } = this.props;
    const mystyle = {
      width: "25px",
      height: "25px",
      position: "relative",
    };

    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Master Rule</CardTitle>
              </CardHeader>
              <CardBody>
                <Table borderless>
                  <tbody>
                    {getUserLoading || getEntityLoading ? (
                      <tr>
                        <td className="text-center">
                          <Spinner color="primary" />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td width="20%">
                          <Input
                            type="select"
                            name="idUser"
                            onChange={(event) => this.handleSelectChange(event)}
                            value={idUser}
                            options={getUserResult.data}
                          >
                            <option value="">--Pilih User--</option>
                            {getUserResult &&
                              getUserResult.data.map((user, index) => (
                                <option key={index} value={user.id}>
                                  {user.fullname}
                                </option>
                              ))}
                          </Input>
                        </td>
                        <td width="20%">
                          <Input
                            type="select"
                            name="idEntity"
                            onChange={(event) => this.handleSelectChange(event)}
                            value={idEntity}
                            options={getEntityResult.data}
                            style={{ display: "block", margin: "auto" }}
                          >
                            <option value="">--Pilih Entitas--</option>
                            {getEntityResult &&
                              getEntityResult.data.map((entity, index) => (
                                <option key={index} value={entity.id}>
                                  {entity.entity_name}
                                </option>
                              ))}
                          </Input>
                        </td>
                        <td>
                          <Button
                            color="success me-2"
                            disabled={getUserLoading}
                            onClick={this.getData}
                          >
                            {searchNameText}
                          </Button>
                        </td>
                        <td className="float-end">
                          <Button
                            color="warning me-2"
                            disabled={getUserLoading}
                            onClick={this.saveChanging}
                          >
                            Simpan
                          </Button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>No</th>
                      <th>Module</th>
                      <th>Menu</th>
                      <th className="text-center">Lihat</th>
                      <th className="text-center">Tambah</th>
                      <th className="text-center">Ubah</th>
                      <th className="text-center">Hapus</th>
                      <th className="text-center">X</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!editRuleLoading && rules && (
                      <tr>
                        <td colSpan={3} align="center"></td>
                        <td align="center">
                          <Input
                            type="checkbox"
                            name="allPermissionRead"
                            style={mystyle}
                            checked={allPermissionRead}
                            onChange={(event) =>
                              this.handleCheckboxAllChange(
                                event,
                                "read_permission"
                              )
                            }
                          />
                        </td>
                        <td align="center">
                          <Input
                            type="checkbox"
                            name="allPermissionCreate"
                            style={mystyle}
                            checked={allPermissionCreate}
                            onChange={(event) =>
                              this.handleCheckboxAllChange(
                                event,
                                "create_permission"
                              )
                            }
                          />
                        </td>
                        <td align="center">
                          <Input
                            type="checkbox"
                            name="allPermissionUpdate"
                            style={mystyle}
                            checked={allPermissionUpdate}
                            onChange={(event) =>
                              this.handleCheckboxAllChange(
                                event,
                                "update_permission"
                              )
                            }
                          />
                        </td>
                        <td align="center">
                          <Input
                            type="checkbox"
                            name="allPermissionDelete"
                            style={mystyle}
                            checked={allPermissionDelete}
                            onChange={(event) =>
                              this.handleCheckboxAllChange(
                                event,
                                "delete_permission"
                              )
                            }
                          />
                        </td>
                        <td align="center"></td>
                      </tr>
                    )}
                    {!editRuleLoading && rules ? (
                      rules.map((rule, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{rule.module_name}</td>
                          <td>{rule.name}</td>
                          <td className="text-center">
                            <Input
                              type="checkbox"
                              name="read_permission"
                              style={mystyle}
                              checked={parseInt(rule.read_permission)}
                              onChange={(event) =>
                                this.handleCheckboxChange(event, index)
                              }
                            />
                          </td>
                          <td className="text-center">
                            <Input
                              type="checkbox"
                              name="create_permission"
                              style={mystyle}
                              checked={parseInt(rule.create_permission)}
                              onChange={(event) =>
                                this.handleCheckboxChange(event, index)
                              }
                            />
                          </td>
                          <td className="text-center">
                            <Input
                              type="checkbox"
                              name="update_permission"
                              style={mystyle}
                              checked={parseInt(rule.update_permission)}
                              onChange={(event) =>
                                this.handleCheckboxChange(event, index)
                              }
                            />
                          </td>
                          <td className="text-center">
                            <Input
                              type="checkbox"
                              name="delete_permission"
                              style={mystyle}
                              checked={parseInt(rule.delete_permission)}
                              onChange={(event) =>
                                this.handleCheckboxChange(event, index)
                              }
                            />
                          </td>
                          <td className="text-center">
                            <Input
                              type="checkbox"
                              name="all_permission"
                              style={mystyle}
                              checked={
                                rule.all_permission === "0" ||
                                rule.all_permission === 0
                                  ? 0
                                  : 1
                              }
                              onChange={(event) =>
                                this.handleCheckboxChange(event, index)
                              }
                            />
                          </td>
                        </tr>
                      ))
                    ) : getRuleLoading || editRuleLoading ? (
                      <tr>
                        <td colSpan={8} align="center">
                          <Spinner color="primary" />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={8} align="center">
                          Data Kosong
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  getUserLoading: state.UserReducer.getUserLoading,
  getUserResult: state.UserReducer.getUserResult,
  getUserError: state.UserReducer.getUserError,

  getEntityLoading: state.EntityReducer.getEntityLoading,
  getEntityResult: state.EntityReducer.getEntityResult,
  getEntityError: state.EntityReducer.getEntityError,

  getRuleLoading: state.RuleReducer.getRuleLoading,
  getRuleResult: state.RuleReducer.getRuleResult,
  getRuleError: state.RuleReducer.getRuleError,

  editRuleLoading: state.RuleReducer.editRuleLoading,
  editRuleResult: state.RuleReducer.editRuleResult,
  editRuleError: state.RuleReducer.editRuleError,
});

export default connect(mapStateToProps, null)(RuleList);
