import React from "react";
import { Spinner } from "reactstrap";

export default function SpinnerTable({ colSpan }) {
  return (
    <tr>
      <td className="text-center" colSpan={colSpan}>
        <Spinner color="primary" />
      </td>
    </tr>
  );
}
