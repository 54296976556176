import {
  dispatchError,
  dispatchLoading,
  dispatchSuccess,
  getLocalStorage,
} from "../utils";
import axios from "axios";

export const GET_RULE_LIST = "GET_RULE_LIST";
export const EDIT_RULE = "EDIT_RULE";

export const getRuleList = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_RULE_LIST);
    axios({
      method: "GET",
      url: "rule?id_user=" + data.idUser + "&id_entity=" + data.idEntity,
      timeout: 120000,
      headers: {
        "Content-Type": "application/json",
        Authorization: getLocalStorage("user").token,
      },
    })
      .then((response) => {
        dispatchSuccess(dispatch, GET_RULE_LIST, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, GET_RULE_LIST, error.response.data);
      });
  };
};

export const editRule = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, EDIT_RULE);
    axios({
      method: "POST",
      url: "update-rule",
      data,
      timeout: 120000,
      headers: {
        "Content-Type": "application/json",
        Authorization: getLocalStorage("user").token,
      },
    })
      .then((response) => {
        dispatchSuccess(dispatch, EDIT_RULE, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, EDIT_RULE, error.response.data);
      });
  };
};
