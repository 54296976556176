import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { connect } from "react-redux";
import swal from "sweetalert";
import { deleteEntity, getEntityList } from "../../../actions/EntityAction";
import {
  PaginationComponent,
  SearchComponent,
} from "../../../components/Table";
import moment from "moment/moment";

class EntityList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      entity_name: "",
      entity_address: "",
      entity_phone: "",
      entity_email: "",
      pic_name: "",
      pic_phone: "",
      pic_email: "",
      accessToken: "",
      urlImageShow: "",

      searchedText: "",
      keyword: "",
      totalData: 0,
      currentPage: 0,
      pageSize: 10,
      pagesCount: 0,
    };

    this.columnLength = 5;
  }

  componentDidMount() {
    this.props.dispatch(getEntityList());
  }

  componentDidUpdate(prevProps) {
    const {
      deleteEntityResult,
      deleteEntityError,
      getEntityError,
      getEntityResult,
    } = this.props;

    if (getEntityResult && prevProps.getEntityResult !== getEntityResult) {
      this.setState({
        pagesCount: Math.ceil(
          getEntityResult.data.length / this.state.pageSize
        ),
        totalData: getEntityResult.data.length,
      });
    }

    if (getEntityError && prevProps.getEntityError !== getEntityError) {
      swal("Error!", getEntityError.messages.error, "error");
      this.props.navigate("/");
    }
    if (
      deleteEntityResult &&
      prevProps.deleteEntityResult !== deleteEntityResult
    ) {
      swal("Success!", deleteEntityResult.messages, "success");
      this.props.dispatch(getEntityList(this.state.accessToken));
    }
    if (
      deleteEntityError &&
      prevProps.deleteEntityError !== deleteEntityError
    ) {
      swal("Error!", deleteEntityError.messages.error, "error");
    }
  }

  deleteData = (id) => {
    swal({
      title: "Anda yakin akan menghapus data ini?",
      text: "Hapus data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.props.dispatch(deleteEntity(id));
      }
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  filterHandleSubmit = (event) => {
    const { keyword } = this.state;
    event.preventDefault();
    this.setState({ searchedText: keyword });
    this.props.dispatch(getEntityList(keyword));
  };

  handleClick = (e, index) => {
    e.preventDefault();
    this.setState({
      currentPage: index,
    });
  };

  render() {
    const {
      keyword,
      searchedText,
      totalData,
      currentPage,
      pageSize,
      pagesCount,
    } = this.state;
    const { getEntityLoading, getEntityResult, deleteEntityLoading } =
      this.props;
    return (
      <>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Master Entity</CardTitle>
              </CardHeader>
              <CardBody>
                <Table borderless>
                  <tbody>
                    <tr>
                      <td width="20%">
                        <Link to="/entity/add" className="btn btn-primary">
                          Tambah Entity
                        </Link>
                      </td>
                      <td className="float-end">
                        <SearchComponent
                          keyword={keyword}
                          searchedText={searchedText}
                          keywordHandleSubmit={this.filterHandleSubmit}
                          handleChange={this.handleChange}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>No</th>
                      <th>Entitas</th>
                      <th>PIC</th>
                      <th>Tanggal Mulai</th>
                      <th width="15%">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!deleteEntityLoading && getEntityResult ? (
                      getEntityResult.data
                        .slice(
                          currentPage * pageSize,
                          (currentPage + 1) * pageSize
                        )
                        .map((entity, index) => (
                          <tr key={index}>
                            <td>{currentPage * pageSize + index + 1}</td>
                            <td>
                              <Table borderless>
                                <tbody>
                                  <tr>
                                    <td width="10%">Nama</td>
                                    <td width="1%">:</td>
                                    <td>{entity.entity_name}</td>
                                  </tr>
                                  <tr>
                                    <td>Alamat</td>
                                    <td>:</td>
                                    <td>{entity.entity_address}</td>
                                  </tr>
                                  <tr>
                                    <td>Telepon</td>
                                    <td>:</td>
                                    <td>{entity.entity_phone}</td>
                                  </tr>
                                  <tr>
                                    <td>Email</td>
                                    <td>:</td>
                                    <td>{entity.entity_email}</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </td>
                            <td>
                              <Table borderless>
                                <tbody>
                                  <tr>
                                    <td width="10%">Nama</td>
                                    <td width="1%">:</td>
                                    <td>{entity.pic_name}</td>
                                  </tr>
                                  <tr>
                                    <td>Telepon</td>
                                    <td>:</td>
                                    <td>{entity.pic_phone}</td>
                                  </tr>
                                  <tr>
                                    <td>Email</td>
                                    <td>:</td>
                                    <td>{entity.pic_email}</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </td>
                            <td>
                              <Table borderless>
                                <tbody>
                                  <tr>
                                    <td width="10%">Tanggal </td>
                                    <td width="1%">:</td>
                                    <td>
                                      {moment(entity.start_date).format(
                                        "DD MMMM YYYY"
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Keterangan</td>
                                    <td>:</td>
                                    <td>{entity.note}</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </td>
                            <td align="center">
                              <Link
                                className="btn btn-warning me-2 mb-2"
                                to={"/entity/" + entity.id}
                              >
                                <i className="nc-icon nc-ruler-pencil"></i> Edit
                              </Link>

                              <Button
                                color="danger"
                                className="me-2 mb-2"
                                onClick={() => this.deleteData(entity.id)}
                              >
                                <i className="nc-icon nc-basket"></i> Delete
                              </Button>
                            </td>
                          </tr>
                        ))
                    ) : getEntityLoading || deleteEntityLoading ? (
                      <tr>
                        <td colSpan={this.columnLength} align="center">
                          <Spinner color="primary" />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={this.columnLength} align="center">
                          Data Kosong
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <PaginationComponent
                  currentPage={currentPage}
                  pagesCount={pagesCount}
                  totalData={totalData}
                  pageSize={pageSize}
                  handleClick={this.handleClick}
                  loading={getEntityLoading || deleteEntityLoading}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  getEntityLoading: state.EntityReducer.getEntityLoading,
  getEntityResult: state.EntityReducer.getEntityResult,
  getEntityError: state.EntityReducer.getEntityError,

  deleteEntityLoading: state.EntityReducer.deleteEntityLoading,
  deleteEntityResult: state.EntityReducer.deleteEntityResult,
  deleteEntityError: state.EntityReducer.deleteEntityError,
});

export default connect(mapStateToProps, null)(EntityList);
