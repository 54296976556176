import {
  dispatchError,
  dispatchLoading,
  dispatchSuccess,
  getLocalStorage,
} from "../utils";
import axios from "axios";

export const GET_INCOME_STATEMENT_LIST = "GET_INCOME_STATEMENT_LIST";

export const getIncomeStatementList = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_INCOME_STATEMENT_LIST);
    axios({
      method: "GET",
      url:
        "income-statement?id_account=" +
        data.idAccount +
        "&start_date=" +
        data.startDateShow +
        "&end_date=" +
        data.endDateShow,
      timeout: 120000,
      headers: {
        "Content-Type": "application/json",
        Authorization: getLocalStorage("user").token,
      },
    })
      .then((response) => {
        dispatchSuccess(dispatch, GET_INCOME_STATEMENT_LIST, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, GET_INCOME_STATEMENT_LIST, error.response.data);
      });
  };
};
