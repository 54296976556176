import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import notFoundImg from "../../images/404.png";

function NotFound() {
  return (
    <Row className="justify-content-center mt-5">
      <Col md="4" className="mt-2">
        <Card className="my-2">
          <CardImg
            alt="Not Found Image"
            src={notFoundImg}
            style={{
              height: 500,
            }}
            top
            width="100%"
          />
          <CardBody>
            <CardTitle tag="h2" className="text-center">
              404 Not Found
            </CardTitle>
            <CardText className="text-center">
              The page you are looking for is not available.{" "}
              <Link to={"/"}>Back</Link>
            </CardText>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default NotFound;
