import {
  GET_ENTITY_LIST,
  GET_DETAIL_ENTITY,
  ADD_ENTITY,
  EDIT_ENTITY,
  DELETE_ENTITY,
  EDIT_ENTITY_START_DATE,
} from "../../actions/EntityAction";

const initialState = {
  getEntityLoading: false,
  getEntityResult: false,
  getEntityError: false,

  getDetailEntityLoading: false,
  getDetailEntityResult: false,
  getDetailEntityError: false,

  addEntityLoading: false,
  addEntityResult: false,
  addEntityError: false,

  editEntityLoading: false,
  editEntityResult: false,
  editEntityError: false,

  editEntityStartDateLoading: false,
  editEntityStartDateResult: false,
  editEntityStartDateError: false,

  deleteEntityLoading: false,
  deleteEntityResult: false,
  deleteEntityError: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ENTITY_LIST:
      return {
        ...state,
        getEntityLoading: action.payload.loading,
        getEntityResult: action.payload.data,
        getEntityError: action.payload.errorMessage,
      };
    case GET_DETAIL_ENTITY:
      return {
        ...state,
        getDetailEntityLoading: action.payload.loading,
        getDetailEntityResult: action.payload.data,
        getDetailEntityError: action.payload.errorMessage,
      };
    case ADD_ENTITY:
      return {
        ...state,
        addEntityLoading: action.payload.loading,
        addEntityResult: action.payload.data,
        addEntityError: action.payload.errorMessage,
      };
    case EDIT_ENTITY:
      return {
        ...state,
        editEntityLoading: action.payload.loading,
        editEntityResult: action.payload.data,
        editEntityError: action.payload.errorMessage,
      };
    case EDIT_ENTITY_START_DATE:
      return {
        ...state,
        editEntityStartDateLoading: action.payload.loading,
        editEntityStartDateResult: action.payload.data,
        editEntityStartDateError: action.payload.errorMessage,
      };
    case DELETE_ENTITY:
      return {
        ...state,
        deleteEntityLoading: action.payload.loading,
        deleteEntityResult: action.payload.data,
        deleteEntityError: action.payload.errorMessage,
      };
    default:
      return state;
  }
}
