import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import swal from "sweetalert";
import { addEntity } from "../../../actions/EntityAction";

class AddEntity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entity_name: "",
      entity_address: "",
      entity_phone: "",
      entity_email: "",
      pic_name: "",
      pic_phone: "",
      pic_email: "",
      start_date: "",
      note: "",
    };
  }

  componentDidMount() {}

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    const { entity_name, entity_address, pic_name, pic_phone, start_date } =
      this.state;
    event.preventDefault();
    if (entity_name && entity_address && pic_name && pic_phone && start_date) {
      this.props.dispatch(addEntity(this.state));
    } else {
      swal(
        "Failed!",
        "Nama entitas, alamat entitas, nama PIC, telepon PIC, dan Tanggal Mulai harus diisi",
        "error"
      );
    }
  };

  componentDidUpdate(prevProps) {
    const { addEntityResult, addEntityError } = this.props;

    if (addEntityResult && prevProps.addEntityResult !== addEntityResult) {
      swal("Success!", addEntityResult.messages, "success");
      this.props.navigate("/entity");
    }
    if (addEntityError && prevProps.addEntityError !== addEntityError) {
      swal("Error!", addEntityError.messages.error, "error");
    }
  }

  render() {
    const {
      entity_name,
      entity_address,
      entity_phone,
      entity_email,
      pic_name,
      pic_phone,
      pic_email,
      start_date,
      note,
    } = this.state;
    const { addEntityLoading } = this.props;
    return (
      <>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Tambah Entity</CardTitle>
              </CardHeader>
              <CardBody>
                {addEntityLoading ? (
                  <div className="text-center">
                    <Spinner color="primary" />
                  </div>
                ) : (
                  <>
                    <form onSubmit={(event) => this.handleSubmit(event)}>
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <label>Nama Entitas</label>
                            <Input
                              type="text"
                              value={entity_name}
                              name="entity_name"
                              autoComplete="off"
                              onChange={(event) => this.handleChange(event)}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <label>Alamat Entitas</label>
                            <Input
                              type="textarea"
                              value={entity_address}
                              name="entity_address"
                              autoComplete="off"
                              onChange={(event) => this.handleChange(event)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <label>Telepon Entitas</label>
                            <Input
                              type="text"
                              value={entity_phone}
                              name="entity_phone"
                              autoComplete="off"
                              onChange={(event) => this.handleChange(event)}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <label>Email</label>
                            <Input
                              type="email"
                              value={entity_email}
                              name="entity_email"
                              autoComplete="off"
                              onChange={(event) => this.handleChange(event)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <label>Nama PIC</label>
                            <Input
                              type="text"
                              value={pic_name}
                              name="pic_name"
                              autoComplete="off"
                              onChange={(event) => this.handleChange(event)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <label>Telepon PIC</label>
                            <Input
                              type="text"
                              value={pic_phone}
                              name="pic_phone"
                              autoComplete="off"
                              onChange={(event) => this.handleChange(event)}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <label>Email PIC</label>
                            <Input
                              type="email"
                              value={pic_email}
                              name="pic_email"
                              autoComplete="off"
                              onChange={(event) => this.handleChange(event)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <label>Tanggal Mulai</label>

                            <Input
                              type="date"
                              value={start_date}
                              name="start_date"
                              onChange={(event) => this.handleChange(event)}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <label>Keterangan</label>
                            <Input
                              type="textarea"
                              value={note}
                              name="note"
                              onChange={(event) => this.handleChange(event)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          {addEntityLoading ? (
                            <Button color="primary" disabled>
                              <Spinner size="sm" color="light" /> Loading
                            </Button>
                          ) : (
                            <>
                              <Button color="primary">Submit</Button>
                              <Link
                                to="/admin/entities"
                                className="btn btn-warning"
                              >
                                Kembali
                              </Link>
                            </>
                          )}
                        </Col>
                      </Row>
                    </form>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  addEntityLoading: state.EntityReducer.addEntityLoading,
  addEntityResult: state.EntityReducer.addEntityResult,
  addEntityError: state.EntityReducer.addEntityError,

  refreshTokenError: state.AuthReducer.refreshTokenError,
  refreshTokenResult: state.AuthReducer.refreshTokenResult,
});

export default connect(mapStateToProps, null)(AddEntity);
