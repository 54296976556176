import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { editUser, getDetailUser } from "../../../actions/UserAction";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import swal from "sweetalert";

function EditUser() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [form, setForm] = useState({
    id,
    fullname: "",
    address: "",
    username: "",
    phone: "",
    email: "",
    start_date: "",
  });
  const {
    getDetailUserLoading,
    getDetailUserResult,
    editUserResult,
    editUserLoading,
    editUserError,
  } = useSelector((state) => state.UserReducer);

  useEffect(() => {
    dispatch(getDetailUser(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (getDetailUserResult && getDetailUserResult.data)
      setForm(getDetailUserResult.data);

    if (editUserResult && editUserResult.messages) {
      swal("Success!", editUserResult.messages, "success");
      navigate("/user");
      editUserResult.messages = false;
    }

    if (editUserError && editUserError.messages)
      swal("Success!", editUserError.messages.error, "success");
  }, [getDetailUserResult, editUserResult, editUserError, navigate]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (form.fullname && form.username && form.address) {
      dispatch(editUser(form));
    } else {
      swal("Failed!", "Nama, alamat dan username  harus diisi", "error");
    }
  };

  return (
    <>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Edit User</CardTitle>
            </CardHeader>
            <CardBody>
              {getDetailUserLoading || editUserLoading ? (
                <div className="text-center">
                  <Spinner color="primary" />
                </div>
              ) : (
                <>
                  <form onSubmit={(event) => handleSubmit(event)}>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Nama Lengkap</label>
                          <Input
                            type="text"
                            value={form.fullname}
                            name="fullname"
                            autoComplete="off"
                            onChange={(event) => handleChange(event)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Alamat</label>
                          <Input
                            type="textarea"
                            value={form.address}
                            name="address"
                            autoComplete="off"
                            onChange={(event) => handleChange(event)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Telepon</label>
                          <Input
                            type="text"
                            value={form.phone}
                            name="phone"
                            autoComplete="off"
                            onChange={(event) => handleChange(event)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Email</label>
                          <Input
                            type="email"
                            value={form.email}
                            name="email"
                            autoComplete="off"
                            onChange={(event) => handleChange(event)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Username</label>
                          <Input
                            type="text"
                            value={form.username}
                            name="username"
                            autoComplete="off"
                            onChange={(event) => handleChange(event)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button color="primary">Submit</Button>
                        <Link to="/user" className="btn btn-warning">
                          Kembali
                        </Link>
                      </Col>
                    </Row>
                  </form>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default EditUser;
