import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardImg,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import swal from "sweetalert";
import { loginUser } from "../../actions/AuthAction";
import { asyncLocalStorage, getLocalStorage, sleep } from "../../utils";
import loadingGif from "../../images/loading.gif";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      isLoading: false,
    };
  }

  componentDidMount() {
    if (getLocalStorage("user") && getLocalStorage("user").token) {
      // this.props.dispatch(getMe());
      swal("Success!", "Anda masih login.", "success").then((result) => {
        if (result) this.props.navigate("/");
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { loginUserResult, loginUserError, getMeResult, getMeError } =
      this.props;
    if (loginUserResult && prevProps.loginUserResult !== loginUserResult) {
      asyncLocalStorage
        .setItem("user", JSON.stringify(loginUserResult.data))
        .then(() => {
          this.setState({ isLoading: true });
          sleep(7000).then(() => {
            swal("Success!", loginUserResult.messages, "success").then(() => {
              this.props.navigate("/");
            });
          });
        });
    }

    if (loginUserError && prevProps.loginUserError !== loginUserError) {
      swal("Error!", loginUserError.messages.error, "error");
      this.setState({ isLoading: false });
    }

    if (getMeResult && prevProps.getMeResult !== getMeResult) {
      this.props.navigate("/");
    }

    if (getMeError && prevProps.getMeError !== getMeError) {
      swal("Error!", getMeError.messages.error, "error");
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    const { username, password } = this.state;
    event.preventDefault();
    if (username && password) {
      this.props.dispatch(loginUser(username, password));
    } else {
      swal("Error", "Isi semua field", "error");
    }
  };

  render() {
    const { password, username, isLoading } = this.state;
    const { loginUserLoading } = this.props;

    return (
      <Row className="justify-content-center mt-5">
        <Col md="4" className="mt-5">
          <Card style={{ backgroundColor: "#f1f1f1" }}>
            <CardHeader tag="h4" className="text-center">
              {getLocalStorage("user") ? "Redirecting..." : "Login"}
            </CardHeader>
            <CardBody>
              {getLocalStorage("user") || isLoading ? (
                <>
                  <CardImg
                    alt="Not Found Image"
                    src={loadingGif}
                    style={{
                      height: 500,
                    }}
                    top
                    width="100%"
                  />
                </>
              ) : (
                <>
                  <form onSubmit={(event) => this.handleSubmit(event)}>
                    <FormGroup>
                      <Label for="username">Username</Label>
                      <Input
                        type="text"
                        name="username"
                        value={username}
                        placeholder="Username"
                        onChange={(event) => this.handleChange(event)}
                      ></Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="password">Password</Label>
                      <Input
                        type="password"
                        name="password"
                        value={password}
                        placeholder="Password"
                        onChange={(event) => this.handleChange(event)}
                      ></Input>
                    </FormGroup>

                    {loginUserLoading ? (
                      <Button color="primary" disabled>
                        <Spinner size="sm" color="light" /> Loading
                      </Button>
                    ) : (
                      <Button color="primary" type="submit">
                        Login
                      </Button>
                    )}
                  </form>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loginUserLoading: state.AuthReducer.loginUserLoading,
  loginUserResult: state.AuthReducer.loginUserResult,
  loginUserError: state.AuthReducer.loginUserError,

  getMeLoading: state.AuthReducer.getMeLoading,
  getMeResult: state.AuthReducer.getMeResult,
  getMeError: state.AuthReducer.getMeError,
});

export default connect(mapStateToProps, null)(Login);
