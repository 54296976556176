import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getEntityList } from "../../../actions/EntityAction";
import { getLedgerList } from "../../../actions/LedgerAction";
import { getAccountList } from "../../../actions/AccountAction";
import moment from "moment/moment";
import { numberWithCommas } from "../../../utils";

class LedgerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idEntity: "",
      idAccount: "",
      startDate: "",
      startDateShow: "",
      endDateShow: moment().format("YYYY-MM-DD"),

      ledgers: false,
      accounts: false,
      saldoAwalDebet: 0,
      saldoAwalKredit: 0,

      saldoAkhirDebet: 0,
      saldoAkhirKredit: 0,
    };
  }

  componentDidMount() {
    this.props.dispatch(getEntityList());
  }

  getData = () => {
    const { startDate, startDateShow, endDateShow, idAccount } = this.state;
    if (!startDateShow || !endDateShow)
      swal("Error", "Isi tanggal awal dan akhir", "error");
    else if (!idAccount) swal("Error", "Pilih Akun", "error");
    else if (
      moment(startDateShow, "YYYY/MM/DD").diff(
        moment(startDate, "YYYY/MM/DD"),
        "days"
      ) < 0
    )
      swal(
        "Error",
        "Tanggal awal tidak boleh lebih kecil dari awal pencatatan",
        "error"
      );
    else this.props.dispatch(getLedgerList(this.state));
  };

  componentDidUpdate(prevProps) {
    const {
      getLedgerError,
      getLedgerResult,
      getEntityError,
      getAccountResult,
    } = this.props;

    if (getLedgerResult && prevProps.getLedgerResult !== getLedgerResult) {
      swal("Sukses", getLedgerResult.messages, "success");
      getLedgerResult.data.forEach((ledger) => {
        this.setState({
          saldoAwalDebet: 0,
          saldoAkhirDebet: 0,

          saldoAwalKredit: 0,
          saldoAkhirKredit: 0,
        });
        if (ledger.normal_balance === "D") {
          this.setState({
            saldoAwalDebet: ledger.start_balance,
            saldoAkhirDebet: ledger.start_balance,
          });
        } else {
          this.setState({
            saldoAwalKredit: ledger.start_balance,
            saldoAkhirKredit: ledger.start_balance,
          });
        }
      });
    }

    if (getAccountResult && prevProps.getAccountResult !== getAccountResult) {
      this.setState({
        accounts: getAccountResult.data,
      });
    }

    if (getEntityError && prevProps.getEntityError !== getEntityError) {
      swal("Error!", getEntityError.messages.error, "error");
      this.props.navigate("/");
    }
    if (getLedgerError && prevProps.getLedgerError !== getLedgerError) {
      swal("Error!", getLedgerError.messages.error, "error");
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSelectChange = (event) => {
    const value = event.target.value;
    this.setState({
      [event.target.name]: value,
    });

    if (event.target.name === "idEntity") {
      let startDateTemp = "";
      this.props.getEntityResult.data.forEach((entity) => {
        if (entity.id === event.target.value) startDateTemp = entity.start_date;
      });
      this.setState({
        accounts: false,
        startDate: moment(startDateTemp).format("YYYY-MM-DD"),
        startDateShow: moment(startDateTemp).format("YYYY-MM-DD"),
      });

      if (value) this.props.dispatch(getAccountList(value));
    }
  };

  render() {
    const {
      idEntity,
      idAccount,
      ledgers,
      accounts,
      startDateShow,
      endDateShow,
      saldoAwalDebet,
      saldoAwalKredit,
      saldoAkhirDebet,
      saldoAkhirKredit,
    } = this.state;
    const {
      getLedgerLoading,
      getEntityLoading,
      getEntityResult,
      getAccountLoading,
    } = this.props;

    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Buku Besar</CardTitle>
              </CardHeader>
              <CardBody>
                <Table borderless>
                  <tbody>
                    {getEntityLoading ? (
                      <tr>
                        <td className="text-center">
                          <Spinner color="primary" />
                        </td>
                      </tr>
                    ) : (
                      <>
                        <tr>
                          <td width="10%" className="align-middle">
                            Entitas
                          </td>
                          <td width="20%">
                            <Input
                              type="select"
                              name="idEntity"
                              onChange={(event) =>
                                this.handleSelectChange(event)
                              }
                              value={idEntity}
                              options={getEntityResult.data}
                              style={{ display: "block", margin: "auto" }}
                            >
                              <option value="">--Pilih Entitas--</option>
                              {getEntityResult &&
                                getEntityResult.data.map((entity, index) => (
                                  <option key={index} value={entity.id}>
                                    {entity.entity_name}
                                  </option>
                                ))}
                            </Input>
                          </td>
                          <td width="1%"></td>
                          <td width="20%"></td>
                          <td></td>
                        </tr>
                        {!accounts && getAccountLoading ? (
                          <tr>
                            <td></td>
                            <td className="text-center">
                              <Spinner color="primary" />
                            </td>
                          </tr>
                        ) : (
                          accounts && (
                            <>
                              <tr>
                                <td className="align-middle">Tanggal</td>
                                <td>
                                  <Input
                                    type="date"
                                    value={startDateShow}
                                    name="startDateShow"
                                    onChange={(event) =>
                                      this.handleChange(event)
                                    }
                                  />
                                </td>
                                <td className="text-center align-middle">-</td>
                                <td>
                                  <Input
                                    type="date"
                                    value={endDateShow}
                                    name="endDateShow"
                                    onChange={(event) =>
                                      this.handleChange(event)
                                    }
                                  />
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td className="align-middle">Nama Akun</td>
                                <td>
                                  <Input
                                    type="select"
                                    name="idAccount"
                                    onChange={(event) =>
                                      this.handleSelectChange(event)
                                    }
                                    value={idAccount}
                                    options={accounts}
                                    style={{ display: "block", margin: "auto" }}
                                  >
                                    <option value="">--Pilih Akun--</option>
                                    {accounts &&
                                      accounts.map((account, index) => (
                                        <option key={index} value={account.id}>
                                          {account.name}
                                        </option>
                                      ))}
                                  </Input>
                                </td>
                                <td></td>
                                <td className="text-center align-middle">
                                  <Button
                                    color="success me-2"
                                    disabled={getEntityLoading}
                                    onClick={this.getData}
                                  >
                                    Lihat Buku Besar
                                  </Button>
                                </td>
                                <td></td>
                              </tr>
                            </>
                          )
                        )}
                      </>
                    )}
                  </tbody>
                </Table>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th width="10%">Tanggal</th>
                      <th width="20%">Keterangan</th>
                      <th width="8%">Mutasi Debet</th>
                      <th width="15%">Mutasi Kredit</th>
                      <th width="5%">Saldo Debet</th>
                      <th width="5%">Saldo Kredit</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center" colSpan={4}>
                        Saldo Awal
                      </td>
                      <td className="text-center">
                        {numberWithCommas(saldoAwalDebet)}
                      </td>
                      <td className="text-center">
                        {numberWithCommas(saldoAwalKredit)}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center" colSpan={4}>
                        Saldo Akhir
                      </td>
                      <td className="text-center">
                        {numberWithCommas(saldoAkhirDebet)}
                      </td>
                      <td className="text-center">
                        {numberWithCommas(saldoAkhirKredit)}
                      </td>
                    </tr>
                    {ledgers ? (
                      ledgers.map((ledger, index) => (
                        <tr key={index}>
                          <td className="align-middle"></td>
                          <td className="text-center"></td>
                          <td className="text-center"></td>
                          <td className="text-center"></td>
                        </tr>
                      ))
                    ) : getLedgerLoading ? (
                      <tr>
                        <td colSpan={6} align="center">
                          <Spinner color="primary" />
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  getEntityLoading: state.EntityReducer.getEntityLoading,
  getEntityResult: state.EntityReducer.getEntityResult,
  getEntityError: state.EntityReducer.getEntityError,

  getLedgerLoading: state.LedgerReducer.getLedgerLoading,
  getLedgerResult: state.LedgerReducer.getLedgerResult,
  getLedgerError: state.LedgerReducer.getLedgerError,

  getAccountLoading: state.AccountReducer.getAccountLoading,
  getAccountResult: state.AccountReducer.getAccountResult,
  getAccountError: state.AccountReducer.getAccountError,
});

export default connect(mapStateToProps, null)(LedgerList);
