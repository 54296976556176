import React from "react";
import { Col, Row, Spinner } from "reactstrap";

export default function SpinnerRow() {
  return (
    <Row>
      <Col md="12" className="text-center">
        <Spinner color="primary" />
      </Col>
    </Row>
  );
}
