import { GET_LEDGER_LIST } from "../../actions/LedgerAction";

const initialState = {
  getLedgerLoading: false,
  getLedgerResult: false,
  getLedgerError: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LEDGER_LIST:
      return {
        ...state,
        getLedgerLoading: action.payload.loading,
        getLedgerResult: action.payload.data,
        getLedgerError: action.payload.errorMessage,
      };
    default:
      return state;
  }
}
