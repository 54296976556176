import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faIdCard,
  faSignOut,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  Navbar,
  Button,
  NavbarToggler,
  Collapse,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavLink,
} from "reactstrap";
import { getLocalStorage } from "../../../utils";
import { Link, useNavigate } from "react-router-dom";
import { logoutUser } from "../../../actions/AuthAction";

const Topbar = ({ toggleSidebar, sidebarIsOpen }) => {
  const navigate = useNavigate();
  const [topbarIsOpen, setTopbarOpen] = useState(true);
  const toggleTopbar = () => {
    setTopbarOpen(!topbarIsOpen);
  };

  const logOutHandle = () => {
    logoutUser(navigate);
  };

  const addSpaceTonFullname = (string) => {
    return string.length < 20
      ? string +
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"
      : string;
  };
  return (
    <Navbar
      color="light"
      className="navbar shadow-sm p-1 mb-2 ms-auto rounded"
      expand="md"
      // container="lg"

      fixed={sidebarIsOpen ? "" : "top"}
    >
      {!sidebarIsOpen && (
        <Button color="dark" onClick={toggleSidebar} outline={true}>
          <FontAwesomeIcon icon={faCog} />
        </Button>
      )}

      <NavbarToggler onClick={toggleTopbar} />
      <Collapse isOpen={topbarIsOpen} navbar>
        <Nav navbar className="ms-auto">
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav>
              <FontAwesomeIcon icon={faUser} className="me-2" />
              {addSpaceTonFullname(getLocalStorage("user").fullname)}
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem>
                <FontAwesomeIcon icon={faIdCard} className="me-2" />
                {addSpaceTonFullname(getLocalStorage("user").role)}
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem>
                <NavLink tag={Link} onClick={logOutHandle} to={"/login"}>
                  <FontAwesomeIcon icon={faSignOut} className="me-2" />
                  Logout
                </NavLink>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default Topbar;
