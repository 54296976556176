import {
  ADD_CASH_ACCOUNT,
  DELETE_CASH_ACCOUNT,
  EDIT_CASH_ACCOUNT,
  GET_CASH_ACCOUNT_LIST,
  GET_CASH_ACCOUNT_BALANCE_TOTAL,
  GET_CASH_ACCOUNT_GROUP_LIST,
} from "../../actions/CashAccountAction";

const initialState = {
  getCashAccountLoading: false,
  getCashAccountResult: false,
  getCashAccountError: false,

  getCashAccountGroupLoading: false,
  getCashAccountGroupResult: false,
  getCashAccountGroupError: false,

  getCashAccountBalanceTotalLoading: false,
  getCashAccountBalanceTotalResult: false,
  getCashAccountBalanceTotalError: false,

  editCashAccountLoading: false,
  editCashAccountResult: false,
  editCashAccountError: false,

  addCashAccountLoading: false,
  addCashAccountResult: false,
  addCashAccountError: false,

  deleteCashAccountLoading: false,
  deleteCashAccountResult: false,
  deleteCashAccountError: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CASH_ACCOUNT_LIST:
      return {
        ...state,
        getCashAccountLoading: action.payload.loading,
        getCashAccountResult: action.payload.data,
        getCashAccountError: action.payload.errorMessage,
      };
    case GET_CASH_ACCOUNT_BALANCE_TOTAL:
      return {
        ...state,
        getCashAccountBalanceTotalLoading: action.payload.loading,
        getCashAccountBalanceTotalResult: action.payload.data,
        getCashAccountBalanceTotalError: action.payload.errorMessage,
      };
    case GET_CASH_ACCOUNT_GROUP_LIST:
      return {
        ...state,
        getCashAccountGroupLoading: action.payload.loading,
        getCashAccountGroupResult: action.payload.data,
        getCashAccountGroupError: action.payload.errorMessage,
      };
    case ADD_CASH_ACCOUNT:
      return {
        ...state,
        addCashAccountLoading: action.payload.loading,
        addCashAccountResult: action.payload.data,
        addCashAccountError: action.payload.errorMessage,
      };
    case EDIT_CASH_ACCOUNT:
      return {
        ...state,
        editCashAccountLoading: action.payload.loading,
        editCashAccountResult: action.payload.data,
        editCashAccountError: action.payload.errorMessage,
      };
    case DELETE_CASH_ACCOUNT:
      return {
        ...state,
        deleteCashAccountLoading: action.payload.loading,
        deleteCashAccountResult: action.payload.data,
        deleteCashAccountError: action.payload.errorMessage,
      };
    default:
      return state;
  }
}
