import {
  dispatchError,
  dispatchLoading,
  dispatchSuccess,
  getLocalStorage,
} from "../utils";
import axios from "axios";

export const GET_CASH_ACCOUNT_LIST = "GET_CASH_ACCOUNT_LIST";
export const GET_CASH_ACCOUNT_BALANCE_TOTAL = "GET_CASH_ACCOUNT_BALANCE_TOTAL";
export const GET_CASH_ACCOUNT_GROUP_LIST = "GET_CASH_ACCOUNT_GROUP_LIST";
export const EDIT_CASH_ACCOUNT = "EDIT_CASH_ACCOUNT";
export const ADD_CASH_ACCOUNT = "ADD_CASH_ACCOUNT";
export const DELETE_CASH_ACCOUNT = "DELETE_CASH_ACCOUNT";

export const getCashAccountList = (idEntity) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_CASH_ACCOUNT_LIST);
    axios({
      method: "GET",
      url: "cash-account?id_entity=" + idEntity,
      timeout: 120000,
      headers: {
        "Content-Type": "application/json",
        Authorization: getLocalStorage("user").token,
      },
    })
      .then((response) => {
        dispatchSuccess(dispatch, GET_CASH_ACCOUNT_LIST, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, GET_CASH_ACCOUNT_LIST, error.response.data);
      });
  };
};

export const getCashAccountBalanceTotal = (idEntity) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_CASH_ACCOUNT_BALANCE_TOTAL);
    axios({
      method: "GET",
      url: "cash-account-balance-total?id_entity=" + idEntity,
      timeout: 120000,
      headers: {
        "Content-Type": "application/json",
        Authorization: getLocalStorage("user").token,
      },
    })
      .then((response) => {
        dispatchSuccess(
          dispatch,
          GET_CASH_ACCOUNT_BALANCE_TOTAL,
          response.data
        );
      })
      .catch((error) => {
        dispatchError(
          dispatch,
          GET_CASH_ACCOUNT_BALANCE_TOTAL,
          error.response.data
        );
      });
  };
};

export const getCashAccountGroupList = (idEntity) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_CASH_ACCOUNT_GROUP_LIST);
    axios({
      method: "GET",
      url: "cash-account-group?id_entity=" + idEntity,
      timeout: 120000,
      headers: {
        "Content-Type": "application/json",
        Authorization: getLocalStorage("user").token,
      },
    })
      .then((response) => {
        dispatchSuccess(dispatch, GET_CASH_ACCOUNT_GROUP_LIST, response.data);
      })
      .catch((error) => {
        dispatchError(
          dispatch,
          GET_CASH_ACCOUNT_GROUP_LIST,
          error.response.data
        );
      });
  };
};

export const AddCashAccount = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, ADD_CASH_ACCOUNT);
    axios({
      method: "POST",
      url: "cash-account",
      data,
      timeout: 120000,
      headers: {
        "Content-Type": "application/json",
        Authorization: getLocalStorage("user").token,
      },
    })
      .then((response) => {
        dispatchSuccess(dispatch, ADD_CASH_ACCOUNT, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, ADD_CASH_ACCOUNT, error.response.data);
      });
  };
};

export const editCashAccount = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, EDIT_CASH_ACCOUNT);
    axios({
      method: "PUT",
      url: "cash-account",
      data,
      timeout: 120000,
      headers: {
        "Content-Type": "application/json",
        Authorization: getLocalStorage("user").token,
      },
    })
      .then((response) => {
        dispatchSuccess(dispatch, EDIT_CASH_ACCOUNT, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, EDIT_CASH_ACCOUNT, error.response.data);
      });
  };
};
export const deleteCashAccount = (id) => {
  return (dispatch) => {
    dispatchLoading(dispatch, DELETE_CASH_ACCOUNT);
    axios({
      method: "DELETE",
      url: "cash-account/" + id,
      timeout: 120000,
      headers: {
        "Content-Type": "application/json",
        Authorization: getLocalStorage("user").token,
      },
    })
      .then((response) => {
        dispatchSuccess(dispatch, DELETE_CASH_ACCOUNT, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, DELETE_CASH_ACCOUNT, error.response.data);
      });
  };
};
