import { Navigate } from "react-router-dom";
import { getLocalStorage } from "../utils";

const ProtectedRoute = (props) => {
  if (getLocalStorage("user") && getLocalStorage("user").token)
    return props.children;
  return <Navigate to="/login" replace />;
};

export default ProtectedRoute;
