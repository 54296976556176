import { GET_INCOME_STATEMENT_LIST } from "../../actions/IncomeStatementAction";

const initialState = {
  getIncomeStatementLoading: false,
  getIncomeStatementResult: false,
  getIncomeStatementError: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_INCOME_STATEMENT_LIST:
      return {
        ...state,
        getIncomeStatementLoading: action.payload.loading,
        getIncomeStatementResult: action.payload.data,
        getIncomeStatementError: action.payload.errorMessage,
      };
    default:
      return state;
  }
}
