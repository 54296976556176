import { LOGIN_USER, GET_ME } from "../../actions/AuthAction";

const initialState = {
  loginUserLoading: false,
  loginUserResult: false,
  loginUserError: false,

  getMeLoading: false,
  getMeResult: false,
  getMeError: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        loginUserLoading: action.payload.loading,
        loginUserResult: action.payload.data,
        loginUserError: action.payload.errorMessage,
      };
    case GET_ME:
      return {
        ...state,
        getMeLoading: action.payload.loading,
        getMeResult: action.payload.data,
        getMeError: action.payload.errorMessage,
      };
    default:
      return state;
  }
}
