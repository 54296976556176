import {
  dispatchError,
  dispatchLoading,
  dispatchSuccess,
  getLocalStorage,
} from "../utils";
import axios from "axios";

export const GET_TRANSACTION_LIST = "GET_TRANSACTION_LIST";
export const EDIT_TRANSACTION = "EDIT_TRANSACTION";
export const ADD_TRANSACTION = "ADD_TRANSACTION";
export const DELETE_TRANSACTION = "DELETE_TRANSACTION";

export const getTransactionList = (
  idEntity,
  startDate,
  endDate,
  idCashAccount
) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_TRANSACTION_LIST);
    axios({
      method: "GET",
      url:
        "transaction?id_entity=" +
        idEntity +
        "&id_cash_account=" +
        idCashAccount +
        "&start_date=" +
        startDate +
        "&end_date=" +
        endDate,
      timeout: 120000,
      headers: {
        "Content-Type": "application/json",
        Authorization: getLocalStorage("user").token,
      },
    })
      .then((response) => {
        dispatchSuccess(dispatch, GET_TRANSACTION_LIST, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, GET_TRANSACTION_LIST, error.response.data);
      });
  };
};

export const AddTransaction = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, ADD_TRANSACTION);
    axios({
      method: "POST",
      url: "transaction",
      data,
      timeout: 120000,
      headers: {
        "Content-Type": "application/json",
        Authorization: getLocalStorage("user").token,
      },
    })
      .then((response) => {
        dispatchSuccess(dispatch, ADD_TRANSACTION, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, ADD_TRANSACTION, error.response.data);
      });
  };
};

export const editTransaction = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, EDIT_TRANSACTION);
    axios({
      method: "PUT",
      url: "transaction",
      data,
      timeout: 120000,
      headers: {
        "Content-Type": "application/json",
        Authorization: getLocalStorage("user").token,
      },
    })
      .then((response) => {
        dispatchSuccess(dispatch, EDIT_TRANSACTION, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, EDIT_TRANSACTION, error.response.data);
      });
  };
};
export const deleteTransaction = (id) => {
  return (dispatch) => {
    dispatchLoading(dispatch, DELETE_TRANSACTION);
    axios({
      method: "DELETE",
      url: "transaction/" + id,
      timeout: 120000,
      headers: {
        "Content-Type": "application/json",
        Authorization: getLocalStorage("user").token,
      },
    })
      .then((response) => {
        dispatchSuccess(dispatch, DELETE_TRANSACTION, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, DELETE_TRANSACTION, error.response.data);
      });
  };
};
