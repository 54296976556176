import moment from "moment";
import React from "react";
import Modal from "react-bootstrap/Modal";
import { Table } from "reactstrap";
import { numberWithCommas } from "../../../utils";

export default function AccountJournalModal({
  show,
  handleClose,
  selectedTransaction,
  selectedCashAccount,
}) {
  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Journal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table responsive bordered>
          <thead className="text-primary">
            <tr>
              <th>Tanggal</th>
              <th>No Transaksi</th>
              <th>D/K</th>
              <th>Kode Akun</th>
              <th>Nama Akun</th>
              <th>Keterangan</th>
              <th>Jumlah</th>
            </tr>
          </thead>
          <tbody>
            {selectedTransaction &&
              selectedCashAccount &&
              selectedTransaction.normal_balance === "K" && (
                <>
                  <tr>
                    <td>
                      {moment(selectedTransaction.date).format("DD-MMMM-YYYY")}
                    </td>
                    <td>{selectedTransaction.number}</td>
                    <td>D</td>
                    <td>{selectedTransaction.chart_of_account_number}</td>
                    <td>{selectedTransaction.name}</td>
                    <td>{selectedTransaction.note}</td>
                    <td>{numberWithCommas(selectedTransaction.value)}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>K</td>
                    <td>{selectedCashAccount.number}</td>
                    <td>{selectedCashAccount.name}</td>
                    <td>{selectedTransaction.note}</td>
                    <td>{numberWithCommas(selectedTransaction.value)}</td>
                  </tr>
                </>
              )}

            {selectedTransaction &&
              selectedCashAccount &&
              selectedTransaction.normal_balance === "D" && (
                <>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>D</td>
                    <td>{selectedCashAccount.number}</td>
                    <td>{selectedCashAccount.name}</td>
                    <td>{selectedTransaction.note}</td>
                    <td>{numberWithCommas(selectedTransaction.value)}</td>
                  </tr>
                  <tr>
                    <td>
                      {moment(selectedTransaction.date).format("DD-MMMM-YYYY")}
                    </td>
                    <td>{selectedTransaction.number}</td>
                    <td>K</td>
                    <td>{selectedTransaction.chart_of_account_number}</td>
                    <td>{selectedTransaction.name}</td>
                    <td>{selectedTransaction.note}</td>
                    <td>{numberWithCommas(selectedTransaction.value)}</td>
                  </tr>
                </>
              )}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
}
