import axios from "axios";
import { API_URL } from "../constants";

export const axiosInstance = axios.create({
  baseURL: API_URL,
  //   headers: { Authorization: getAuthorizationHeader() },
});

export const getAuthorizationHeader = () =>
  localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).token
    : axiosInstance.defaults.headers.Authorization;
