import { combineReducers } from "redux";
import UserReducer from "./user";
import RuleReducer from "./rule";
import AuthReducer from "./auth";
import EntityReducer from "./entity";
import AccountReducer from "./account";
import LedgerReducer from "./ledger";
import IncomeStatementReducer from "./incomeStatement";
import AccountGroupReducer from "./accountGroup";
import CashAccountReducer from "./cashAccount";
import TransactionReducer from "./transaction";

export default combineReducers({
  UserReducer,
  AuthReducer,
  EntityReducer,
  RuleReducer,
  AccountReducer,
  AccountGroupReducer,
  LedgerReducer,
  IncomeStatementReducer,
  CashAccountReducer,
  TransactionReducer,
});
