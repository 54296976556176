import React, { useState } from "react";
import SideBar from "../../components/layout/sidebar/SideBar";
import Content from "../../components/layout/content/Content";

function Dashboard() {
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);

  return (
    <div className="App wrapper">
      <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
      <Content toggleSidebar={toggleSidebar} sidebarIsOpen={sidebarIsOpen} />
    </div>
  );
}

export default Dashboard;
