import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getEntityList } from "../../../actions/EntityAction";
import {
  AddCashAccount,
  deleteCashAccount,
  editCashAccount,
  getCashAccountGroupList,
  getCashAccountList,
} from "../../../actions/CashAccountAction";
import { checkNumberWithCommas } from "../../../utils";
import moment from "moment";

class CashAccountList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idEntity: "",

      cashAccounts: false,

      isEditing: false,
      searchText: "Cari",
    };

    this.columnLength = 6;
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { idEntity } = this.state;
    if (idEntity) {
      this.setState({ cashAccounts: false });
      this.props.dispatch(getCashAccountList(idEntity));
      this.props.dispatch(getCashAccountGroupList(idEntity));
    } else if (!idEntity) {
      this.props.dispatch(getEntityList());
    }
  };

  setData = (data) => {
    this.setState({
      cashAccounts: data,
      searchText: "Refresh",
      isEditing: false,
    });
  };

  componentDidUpdate(prevProps) {
    const {
      editCashAccountResult,
      editCashAccountError,
      getCashAccountError,
      getCashAccountResult,
      getEntityError,
      addCashAccountResult,
      addCashAccountError,
      getCashAccountGroupError,
      deleteCashAccountResult,
      deleteCashAccountError,
    } = this.props;
    if (
      editCashAccountResult &&
      prevProps.editCashAccountResult !== editCashAccountResult
    ) {
      swal("Sukses", editCashAccountResult.messages, "success");
      this.getData();
    }

    if (
      deleteCashAccountError &&
      prevProps.deleteCashAccountError !== deleteCashAccountError
    ) {
      swal("Error", deleteCashAccountError.messages.error, "error");
    }

    if (
      deleteCashAccountResult &&
      prevProps.deleteCashAccountResult !== deleteCashAccountResult
    ) {
      swal("Sukses", deleteCashAccountResult.messages, "success");
      this.getData();
    }

    if (
      addCashAccountResult &&
      prevProps.addCashAccountResult !== addCashAccountResult
    ) {
      this.getData();
    }

    if (
      addCashAccountError &&
      prevProps.addCashAccountError !== addCashAccountError
    ) {
      swal("Error", addCashAccountError.messages.error, "error");
    }

    if (
      getCashAccountResult &&
      prevProps.getCashAccountResult !== getCashAccountResult
    ) {
      this.setData(getCashAccountResult.data);
    }

    if (
      getCashAccountGroupError &&
      prevProps.getCashAccountGroupError !== getCashAccountGroupError
    ) {
      swal("Error!", getCashAccountGroupError.messages.error, "error");
    }

    if (getEntityError && prevProps.getEntityError !== getEntityError) {
      swal("Error!", getEntityError.messages.error, "error");
      this.props.navigate("/");
    }
    if (
      getCashAccountError &&
      prevProps.getCashAccountError !== getCashAccountError
    ) {
      swal("Error!", getCashAccountError.messages.error, "error");
    }

    if (
      editCashAccountError &&
      prevProps.editCashAccountError !== editCashAccountError
    ) {
      swal("Error!", editCashAccountError.messages.error, "error");
    }
  }

  handleChange = (event, index) => {
    const { cashAccounts } = this.state;
    const updatedCashAccounts = [...cashAccounts];
    updatedCashAccounts[index][event.target.name] = event.target.value;

    this.setData(updatedCashAccounts);
    this.setState({
      isEditing: true,
    });
  };

  handleSelectChange = (event) => {
    const value = event.target.value;
    this.setState({
      [event.target.name]: value,
      searchText: "Cari",
      cashAccounts: false,
    });
  };

  handleCheckboxChange = (event, index) => {
    const { cashAccounts } = this.state;

    const updatedCashAccounts = [...cashAccounts];
    const checked = event.target.checked ? 1 : 0;
    updatedCashAccounts[index][event.target.name] = checked;

    this.setState({
      cashAccounts: updatedCashAccounts,
      isEditing: true,
    });
  };

  saveChanging = () => {
    const { cashAccounts } = this.state;

    this.props.dispatch(editCashAccount(cashAccounts));
  };

  addData = () => {
    const { idEntity, isEditing } = this.state;
    if (isEditing)
      swal(
        "Error",
        "Data sedang diubah, simpan data terlebih dahulu!",
        "error"
      );
    else this.props.dispatch(AddCashAccount({ idEntity }));
  };

  deleteData = (id) => {
    const { isEditing } = this.state;

    if (isEditing)
      swal(
        "Error",
        "Data sedang diubah, simpan data terlebih dahulu!",
        "error"
      );
    else this.props.dispatch(deleteCashAccount(id));
  };

  checkNumberCashAccountNumber = (x) => {
    return !isNaN(+x) ? x.toString().replace(".", "") : "";
  };

  render() {
    const { idEntity, cashAccounts, searchText } = this.state;
    const {
      getCashAccountLoading,
      editCashAccountLoading,
      getEntityLoading,
      getEntityResult,
      getCashAccountGroupResult,
      deleteCashAccountLoading,
      addCashAccountLoading,
    } = this.props;
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Rekening Kas</CardTitle>
              </CardHeader>
              <CardBody>
                <Table borderless>
                  <tbody>
                    {getEntityLoading ? (
                      <tr>
                        <td className="text-center">
                          <Spinner color="primary" />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td width="20%">
                          <Input
                            type="select"
                            name="idEntity"
                            onChange={(event) => this.handleSelectChange(event)}
                            value={idEntity}
                            options={getEntityResult.data}
                            style={{ display: "block", margin: "auto" }}
                          >
                            <option value="">--Pilih Entitas--</option>
                            {getEntityResult &&
                              getEntityResult.data.map((entity, index) => (
                                <option key={index} value={entity.id}>
                                  {entity.entity_name}
                                </option>
                              ))}
                          </Input>
                        </td>
                        <td>
                          <Button
                            color="success me-2"
                            disabled={getEntityLoading}
                            onClick={this.getData}
                          >
                            {searchText}
                          </Button>
                        </td>
                        <td className="float-end">
                          <Button
                            color="primary me-2"
                            disabled={!cashAccounts}
                            onClick={() => this.addData()}
                          >
                            Tambah
                          </Button>
                          <Button
                            color="warning me-2"
                            disabled={!cashAccounts}
                            onClick={this.saveChanging}
                          >
                            Simpan
                          </Button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th width="5%">No</th>
                      <th>Nama Rekening</th>
                      <th width="20%">Tanggal Saldo Awal</th>
                      <th width="15%">Nilai Saldo Awal</th>
                      <th width="25%">Akun</th>
                      <th width="5%" className="text-center">
                        *
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!addCashAccountLoading &&
                    !deleteCashAccountLoading &&
                    !editCashAccountLoading &&
                    cashAccounts ? (
                      cashAccounts.map((account, index) => (
                        <tr key={index}>
                          <td className="align-middle">{index + 1}</td>
                          <td>
                            <Input
                              type="text"
                              value={account.name}
                              name="name"
                              autoComplete="off"
                              onChange={(event) =>
                                this.handleChange(event, index)
                              }
                            />
                          </td>
                          <td>
                            <Input
                              type="date"
                              value={moment(account.start_balance_date).format(
                                "YYYY-MM-DD"
                              )}
                              name="start_balance_date"
                              onChange={(event) =>
                                this.handleChange(event, index)
                              }
                            />
                          </td>
                          <td>
                            <Input
                              type="text"
                              value={checkNumberWithCommas(
                                account.start_balance
                              )}
                              name="start_balance"
                              autoComplete="off"
                              onChange={(event) =>
                                this.handleChange(event, index)
                              }
                            />
                          </td>
                          <td>
                            <Input
                              type="select"
                              name="id_chart_of_account"
                              onChange={(event) =>
                                this.handleChange(event, index)
                              }
                              value={account.id_chart_of_account}
                              options={getCashAccountGroupResult.data}
                              style={{ display: "block", margin: "auto" }}
                            >
                              {getCashAccountGroupResult &&
                                getCashAccountGroupResult.data.map(
                                  (detail, index) => (
                                    <option key={index} value={detail.id}>
                                      {detail.name}
                                    </option>
                                  )
                                )}
                            </Input>
                          </td>
                          <td className="text-center">
                            <Button
                              color="danger"
                              onClick={() => this.deleteData(account.id)}
                            >
                              X
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : addCashAccountLoading ||
                      getCashAccountLoading ||
                      editCashAccountLoading ||
                      deleteCashAccountLoading ? (
                      <tr>
                        <td colSpan={this.columnLength} align="center">
                          <Spinner color="primary" />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={this.columnLength} align="center">
                          Data Kosong
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  getEntityLoading: state.EntityReducer.getEntityLoading,
  getEntityResult: state.EntityReducer.getEntityResult,
  getEntityError: state.EntityReducer.getEntityError,

  getCashAccountGroupLoading:
    state.CashAccountReducer.getCashAccountGroupLoading,
  getCashAccountGroupResult: state.CashAccountReducer.getCashAccountGroupResult,
  getCashAccountGroupError: state.CashAccountReducer.getCashAccountGroupError,

  getCashAccountLoading: state.CashAccountReducer.getCashAccountLoading,
  getCashAccountResult: state.CashAccountReducer.getCashAccountResult,
  getCashAccountError: state.CashAccountReducer.getCashAccountError,

  editCashAccountLoading: state.CashAccountReducer.editCashAccountLoading,
  editCashAccountResult: state.CashAccountReducer.editCashAccountResult,
  editCashAccountError: state.CashAccountReducer.editCashAccountError,

  addCashAccountLoading: state.CashAccountReducer.addCashAccountLoading,
  addCashAccountResult: state.CashAccountReducer.addCashAccountResult,
  addCashAccountError: state.CashAccountReducer.addCashAccountError,

  deleteCashAccountLoading: state.CashAccountReducer.deleteCashAccountLoading,
  deleteCashAccountResult: state.CashAccountReducer.deleteCashAccountResult,
  deleteCashAccountError: state.CashAccountReducer.deleteCashAccountError,
});

export default connect(mapStateToProps, null)(CashAccountList);
