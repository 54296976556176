import { GET_ACCOUNT_GROUP_LIST } from "../../actions/AccountGroupAction";

const initialState = {
  getAccountGroupLoading: false,
  getAccountGroupResult: false,
  getAccountGroupError: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNT_GROUP_LIST:
      return {
        ...state,
        getAccountGroupLoading: action.payload.loading,
        getAccountGroupResult: action.payload.data,
        getAccountGroupError: action.payload.errorMessage,
      };
    default:
      return state;
  }
}
