import { faKey, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import swal from "sweetalert";
import { deleteUser, editUser, getUserList } from "../../../actions/UserAction";
import { PasswordModal } from "../../../components/Modal/PasswordModal";
import {
  PaginationComponent,
  SearchComponent,
} from "../../../components/Table";

class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      fullname: "",
      password: "",
      modalPasswordToggle: false,
      searchedText: "",
      keyword: "",
      totalData: 0,
      currentPage: 0,
      pageSize: 10,
      pagesCount: 0,
    };

    this.columnLength = 7;
  }

  componentDidUpdate(prevProps) {
    const {
      deleteUserResult,
      deleteUserError,
      editUserResult,
      editUserError,
      getUserError,
      getUserResult,
    } = this.props;

    if (getUserError && prevProps.getUserError !== getUserError) {
      swal("Error!", getUserError.messages, "error");
      this.props.navigate("/admin/dashboard");
    }

    if (getUserResult && prevProps.getUserResult !== getUserResult) {
      this.setState({
        pagesCount: Math.ceil(getUserResult.data.length / this.state.pageSize),
        totalData: getUserResult.data.length,
      });
    }

    if (deleteUserResult && prevProps.deleteUserResult !== deleteUserResult) {
      swal("Success!", deleteUserResult.messages, "success");
      this.props.dispatch(getUserList());
    }
    if (deleteUserError && prevProps.deleteUserError !== deleteUserError) {
      swal("Error!", deleteUserError.messages, "error");
    }
    if (editUserResult && prevProps.editUserResult !== editUserResult) {
      swal("Success!", editUserResult.messages, "success");
      this.setState({ modalPasswordToggle: false, password: "" });
    }
    if (editUserError && prevProps.editUserError !== editUserError) {
      swal("Error!", editUserError.messages.error, "error");
      this.setState({ modalPasswordToggle: false, password: "" });
    }
  }

  deleteData = (id) => {
    swal({
      title: "Anda yakin akan menghapus data ini?",
      text: "Hapus data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.props.dispatch(deleteUser(id));
      }
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    const { password } = this.state;
    event.preventDefault();
    if (password) {
      this.props.dispatch(editUser(this.state));
    } else {
      swal("Failed!", "Isi Password!", "error");
    }
  };

  componentDidMount() {
    this.props.dispatch(getUserList());
  }

  filterHandleSubmit = (event) => {
    const { keyword } = this.state;
    event.preventDefault();
    this.setState({ searchedText: keyword });
    this.props.dispatch(getUserList(keyword));
  };

  handleClick = (e, index) => {
    e.preventDefault();
    this.setState({
      currentPage: index,
    });
  };

  changePassword = (id, fullname) => {
    swal({
      text: "Ubah password " + fullname,
      content: "input",
      button: {
        text: "Ubah",
      },
    }).then((password) => {
      if (password) {
        this.props.dispatch(editUser({ password, id }));
      }
    });
  };

  render() {
    const {
      fullname,
      password,
      modalPasswordToggle,
      keyword,
      searchedText,
      totalData,
      currentPage,
      pageSize,
      pagesCount,
    } = this.state;
    const {
      getUserLoading,
      deleteUserLoading,
      getUserResult,
      editUserLoading,
    } = this.props;
    return (
      <>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Master User</CardTitle>
              </CardHeader>
              <CardBody>
                <Table borderless>
                  <tbody>
                    <tr>
                      <td width="20%">
                        <Link to="/user/add" className="btn btn-primary">
                          Tambah User
                        </Link>
                      </td>
                      <td className="float-end">
                        <SearchComponent
                          keyword={keyword}
                          searchedText={searchedText}
                          keywordHandleSubmit={this.filterHandleSubmit}
                          handleChange={this.handleChange}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>No</th>
                      <th>Nama Lengkap</th>
                      <th>Username</th>
                      <th>Detail</th>
                      <th width="15%">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!deleteUserLoading &&
                    !editUserLoading &&
                    getUserResult &&
                    getUserResult.data ? (
                      getUserResult.data
                        .slice(
                          currentPage * pageSize,
                          (currentPage + 1) * pageSize
                        )
                        .map((user, index) => (
                          <tr key={index}>
                            <td>{currentPage * pageSize + index + 1}</td>
                            <td>{user.fullname}</td>
                            <td>{user.username}</td>
                            <td>
                              <Table borderless>
                                <tbody>
                                  <tr>
                                    <td width="15%">Alamat</td>
                                    <td width="1%">:</td>
                                    <td>{user.address}</td>
                                  </tr>
                                  <tr>
                                    <td>Telepon</td>
                                    <td>:</td>
                                    <td>{user.phone}</td>
                                  </tr>
                                  <tr>
                                    <td>Email</td>
                                    <td>:</td>
                                    <td>{user.email}</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </td>
                            <td align="center">
                              <Button
                                size="sm"
                                color="secondary"
                                className="me-2 mb-2"
                                onClick={() =>
                                  this.changePassword(user.id, user.fullname)
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faKey}
                                  className="me-2"
                                />
                                Pass
                              </Button>
                              {user.username !== "superadmin" && (
                                <>
                                  <Link
                                    className="btn btn-sm btn-warning me-2 mb-2"
                                    to={"/user/" + user.id}
                                  >
                                    <FontAwesomeIcon
                                      icon={faPencil}
                                      className="me-2"
                                    />
                                    Edit
                                  </Link>

                                  <Button
                                    size="sm"
                                    color="danger"
                                    className="me-2 mb-2"
                                    onClick={() => this.deleteData(user.id)}
                                  >
                                    <FontAwesomeIcon
                                      icon={faTrash}
                                      className="me-2"
                                    />{" "}
                                    Delete
                                  </Button>
                                </>
                              )}
                            </td>
                          </tr>
                        ))
                    ) : editUserLoading ||
                      getUserLoading ||
                      deleteUserLoading ? (
                      <tr>
                        <td colSpan={this.columnLength} align="center">
                          <Spinner color="primary" />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={this.columnLength} align="center">
                          Data Kosong
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <br />
                <PaginationComponent
                  currentPage={currentPage}
                  pagesCount={pagesCount}
                  totalData={totalData}
                  pageSize={pageSize}
                  handleClick={this.handleClick}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <PasswordModal
          modalPasswordToggle={modalPasswordToggle}
          handleClose={() => this.setState({ modalPasswordToggle: false })}
          name={fullname}
          password={password}
          editUserLoading={editUserLoading}
          handleChange={(event) => this.handleChange(event)}
          handleSubmit={(event) => this.handleSubmit(event)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  getUserLoading: state.UserReducer.getUserLoading,
  getUserResult: state.UserReducer.getUserResult,
  getUserError: state.UserReducer.getUserError,

  deleteUserLoading: state.UserReducer.deleteUserLoading,
  deleteUserResult: state.UserReducer.deleteUserResult,
  deleteUserError: state.UserReducer.deleteUserError,

  editUserLoading: state.UserReducer.editUserLoading,
  editUserResult: state.UserReducer.editUserResult,
  editUserError: state.UserReducer.editUserError,
});

export default connect(mapStateToProps, null)(UserList);
