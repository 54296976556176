import React from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { checkNumberWithCommas } from "../../../utils";

export default function TransactionOutModal({
  show,
  handleClose,
  handleSubmit,
  transactionData,
  details,
  handleChange,
  selectedCashAccount,
  detailHandleChange,
  deleteDetail,
  addDetail,
}) {
  const { getCashOutAccountResult } = useSelector(
    (state) => state.AccountReducer
  );

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Tambah Pengeluaran</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={3}>
              <Label>No Transaksi</Label>
            </Col>
            <Col md={9}>
              <Label>{transactionData.number}</Label>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label>Nama Rekening</Label>
            </Col>
            <Col md={4}>{selectedCashAccount.chart_of_account_name}</Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label>Buku Kas</Label>
            </Col>
            <Col md={4}>{selectedCashAccount.name}</Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label>Tanggal</Label>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Input
                  type="date"
                  value={transactionData.date}
                  name="date"
                  autoComplete="off"
                  onChange={(event) => handleChange(event)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table responsive>
                <thead className="text-primary">
                  <tr>
                    <th>Akun Pengeluaran</th>
                    <th width="40%">Keterangan</th>
                    <th width="20%">Jumlah</th>
                    <th width="5%" className="text-center">
                      *
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {details.map((detail, index) => (
                    <tr key={index}>
                      <td>
                        <Input
                          type="select"
                          name="id_chart_of_account"
                          onChange={(event) => detailHandleChange(event, index)}
                          value={detail.id_chart_of_account}
                          options={getCashOutAccountResult.data}
                          style={{ display: "block", margin: "auto" }}
                        >
                          <option value={""}>----Pilih Akun----</option>
                          {getCashOutAccountResult &&
                            getCashOutAccountResult.data.map(
                              (account, index) => (
                                <option key={index} value={account.id}>
                                  {account.name}
                                </option>
                              )
                            )}
                        </Input>
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={detail.note}
                          name="note"
                          autoComplete="off"
                          onChange={(event) => detailHandleChange(event, index)}
                        />
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={checkNumberWithCommas(detail.value)}
                          name="value"
                          autoComplete="off"
                          onChange={(event) => detailHandleChange(event, index)}
                        />
                      </td>
                      <td>
                        <Button
                          color="danger"
                          onClick={() => deleteDetail(index)}
                        >
                          X
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              {false ? (
                <Button color="primary" disabled>
                  <Spinner size="sm" color="light" /> Loading
                </Button>
              ) : (
                <>
                  <Button color="primary">Submit</Button>
                  <Button color="warning" onClick={handleClose}>
                    Cancel
                  </Button>
                </>
              )}
            </Col>
            <Col>
              <Button color="success" className="float-end" onClick={addDetail}>
                Tambah
              </Button>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
}
