import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import {
  addEntityStartDate,
  deleteEntityStartDate,
  editEntity,
  editEntityStartDate,
  getDetailEntity,
} from "../../../actions/EntityAction";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
// import { Modal } from "bootstrap";

export default function EditEntity() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    id,
    entity_name: "",
    entity_address: "",
    entity_phone: "",
    entity_email: "",
    pic_name: "",
    pic_phone: "",
    pic_email: "",
  });

  const [startDates, setStartDates] = useState(false);
  const [startDateForm, setStartDateForm] = useState({
    id_entity: id,
    start_date: "",
    note: "",
    is_default: 0,
  });
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {
    getDetailEntityLoading,
    getDetailEntityResult,
    addEntityResult,
    addEntityLoading,
    addEntityError,
    editEntityResult,
    editEntityLoading,
    editEntityError,
    deleteEntityLoading,
    deleteEntityResult,
    deleteEntityError,
    editEntityStartDateResult,
    editEntityStartDateLoading,
    editEntityStartDateError,
  } = useSelector((state) => state.EntityReducer);

  useEffect(() => {
    dispatch(getDetailEntity(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (getDetailEntityResult && getDetailEntityResult.data) {
      setForm(getDetailEntityResult.data);
      setStartDates(getDetailEntityResult.start_dates);
    }

    if (addEntityResult && addEntityResult.messages) {
      swal("Success!", addEntityResult.messages, "success");
      dispatch(getDetailEntity(id));
      addEntityResult.messages = "";
      setShow(false);
      setStartDateForm({
        ...startDateForm,
        note: "",
        start_date: "",
      });
    }

    if (addEntityError && addEntityError.messages)
      swal("Error!", addEntityError.messages.error, "error");

    if (editEntityResult && editEntityResult.messages) {
      swal("Success!", editEntityResult.messages, "success");
      navigate("/entity");
      editEntityResult.messages = false;
    }

    if (editEntityError && editEntityError.messages)
      swal("Error!", editEntityError.messages.error, "error");

    if (editEntityStartDateResult && editEntityStartDateResult.messages) {
      swal("Success!", editEntityStartDateResult.messages, "success");
      editEntityStartDateResult.messages = "";
    }

    if (editEntityStartDateError && editEntityStartDateError.messages) {
      swal("Error!", editEntityStartDateError.messages.error, "error");
      dispatch(getDetailEntity(id));
    }

    if (deleteEntityError && deleteEntityError.messages)
      swal("Error!", deleteEntityError.messages.error, "error");

    if (deleteEntityResult && deleteEntityResult.messages) {
      swal("Success!", deleteEntityResult.messages, "success");
      deleteEntityResult.messages = "";
      dispatch(getDetailEntity(id));
    }
  }, [
    editEntityStartDateResult,
    editEntityStartDateError,
    getDetailEntityResult,
    addEntityResult,
    addEntityError,
    editEntityResult,
    editEntityError,
    deleteEntityError,
    deleteEntityResult,
    navigate,
    id,
    dispatch,
    startDateForm,
  ]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeStartDate = (e) => {
    setStartDateForm({
      ...startDateForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      form.entity_name &&
      form.entity_address &&
      form.pic_name &&
      form.pic_phone
    ) {
      dispatch(editEntity(form));
    } else {
      swal(
        "Failed!",
        "Nama entitas, alamat entitas, nama PIC, dan telepon PIC harus diisi",
        "error"
      );
    }
  };

  const handleSubmitStartDate = (event) => {
    event.preventDefault();
    if (startDateForm.start_date) {
      dispatch(addEntityStartDate(startDateForm));
    } else {
      swal(
        "Failed!",
        "Nama entitas, alamat entitas, nama PIC, dan telepon PIC harus diisi",
        "error"
      );
    }
  };

  const mystyle = {
    width: "25px",
    height: "25px",
    position: "relative",
  };

  const deleteData = (id) => {
    swal({
      title: "Anda yakin akan menghapus data ini?",
      text: "Hapus data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteEntityStartDate(id));
      }
    });
  };

  const handleCheckboxChange = (event, index) => {
    const updatedStartDates = [...startDates];

    updatedStartDates.forEach((date) => {
      date[event.target.name] = 0;
    });

    const checked = event.target.checked ? 1 : 0;

    if (updatedStartDates[index][event.target.name] !== checked) {
      updatedStartDates[index][event.target.name] = checked;

      setStartDates(updatedStartDates);
      dispatch(editEntityStartDate(updatedStartDates[index]));
    }
  };

  return (
    <>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Edit Entity</CardTitle>
            </CardHeader>
            <CardBody>
              {getDetailEntityLoading || editEntityLoading ? (
                <div className="text-center">
                  <Spinner color="primary" />
                </div>
              ) : (
                <>
                  <form onSubmit={(event) => handleSubmit(event)}>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Nama Entitas</label>
                          <Input
                            type="text"
                            value={form.entity_name}
                            name="entity_name"
                            autoComplete="off"
                            onChange={(event) => handleChange(event)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Alamat Entitas</label>
                          <Input
                            type="textarea"
                            value={form.entity_address}
                            name="entity_address"
                            autoComplete="off"
                            onChange={(event) => handleChange(event)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Telepon Entitas</label>
                          <Input
                            type="text"
                            value={form.entity_phone}
                            name="entity_phone"
                            autoComplete="off"
                            onChange={(event) => handleChange(event)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Email</label>
                          <Input
                            type="email"
                            value={form.entity_email}
                            name="entity_email"
                            autoComplete="off"
                            onChange={(event) => handleChange(event)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Nama PIC</label>
                          <Input
                            type="text"
                            value={form.pic_name}
                            name="pic_name"
                            autoComplete="off"
                            onChange={(event) => handleChange(event)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Telepon PIC</label>
                          <Input
                            type="text"
                            value={form.pic_phone}
                            name="pic_phone"
                            autoComplete="off"
                            onChange={(event) => handleChange(event)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Email PIC</label>
                          <Input
                            type="email"
                            value={form.pic_email}
                            name="pic_email"
                            autoComplete="off"
                            onChange={(event) => handleChange(event)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr />

                    <Row>
                      <Col>
                        <Button color="primary">Submit</Button>
                        <Link to="/entity" className="btn btn-warning">
                          Kembali
                        </Link>
                      </Col>
                    </Row>
                  </form>
                </>
              )}
              <hr />
              <hr />
              <CardTitle tag="h4">Data Tanggal Mulai</CardTitle>
              <hr />
              <Button className="mb-3" color="primary" onClick={handleShow}>
                Tambah
              </Button>
              <Table responsive>
                <thead className="text-primary">
                  <tr>
                    <th>No</th>
                    <th>Tanggal Mulai</th>
                    <th>Keterangan</th>
                    <th width="10%" className="text-center">
                      Terpilih
                    </th>
                    <th width="10%" className="text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {startDates &&
                  !deleteEntityLoading &&
                  !editEntityStartDateLoading ? (
                    startDates.map((date, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {moment(date.start_date).format("DD MMMM YYYY")}
                        </td>
                        <td>{date.note}</td>

                        <td className="text-center">
                          <Input
                            type="checkbox"
                            name="is_default"
                            style={mystyle}
                            checked={parseInt(date.is_default)}
                            onChange={(event) =>
                              handleCheckboxChange(event, index)
                            }
                          />
                        </td>

                        <td align="center">
                          <Button
                            color="danger"
                            className="me-2 mb-2"
                            onClick={() => deleteData(date.id)}
                          >
                            <i className="nc-icon nc-basket"></i> Delete
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : getDetailEntityLoading ||
                    editEntityStartDateLoading ||
                    deleteEntityLoading ? (
                    <tr>
                      <td colSpan={5} align="center">
                        <Spinner color="primary" />
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan={5} align="center">
                        Data Kosong
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Tambah Tanggal Mulai</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitStartDate}>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Input
                    type="date"
                    value={startDateForm.start_date}
                    name="start_date"
                    autoComplete="off"
                    onChange={(event) => handleChangeStartDate(event)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Input
                    type="textarea"
                    value={startDateForm.note}
                    name="note"
                    autoComplete="off"
                    onChange={(event) => handleChangeStartDate(event)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                {addEntityLoading ? (
                  <Button color="primary" disabled>
                    <Spinner size="sm" color="light" /> Loading
                  </Button>
                ) : (
                  <>
                    <Button color="primary">Submit</Button>
                    <Button color="warning" onClick={handleClose}>
                      Cancel
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
